/**
 * This component is shown immediately after someone
 * has shared their Earworm, and allows them to
 * curate the genres attached to it
 */

import React, { useState } from "react"
import { gql, useApolloClient, useMutation } from "@apollo/client"
import Twemoji from "react-twemoji"

import { useUser } from "../../contexts/user-context"
import AddGenres from "./AddGenres"
import GenreTag from "../../GenreTag"
import ImageFadeLoad from "../../ImageFadeLoad"
import LinkButton from "../../LinkButton"

// Icons
import { ReactComponent as Remove } from "../../icons/close.svg"

// Types
import { EarwormType } from "../../types/earworm"
import { Genre } from "../../types/genre"
import {
  genreTagsStyle,
  goNextStyle,
  previewContainerStyle,
  previewMetaStyle,
  removeIconStyle,
  styledTwmojiStyle,
} from "./ChooseGenres.css"

const ADD_GENRE = gql`
  mutation addGenreToEarworm($earwormId: String!, $genre: String!) {
    addGenreToEarworm(earwormId: $earwormId, genre: $genre) {
      earworm {
        genres {
          name
        }
        id
      }
    }
  }
`

const REMOVE_GENRE = gql`
  mutation removeGenreFromEarworm($earwormId: String!, $genre: String!) {
    removeGenreFromEarworm(earwormId: $earwormId, genre: $genre) {
      earworm {
        genres {
          name
        }
        id
      }
    }
  }
`

/*
 * Returns true if the passed in genre is contained in the passed
 * in removedGenres array
 */
export const isGenreRemoved = (removedGenres: Array<Genre>, genre: Genre) =>
  removedGenres.findIndex((g) => g.name === genre.name) > -1

type ChooseGenresProps = {
  earworm: EarwormType
}

export default function ChooseGenres(props: ChooseGenresProps) {
  const client = useApolloClient()
  const { user } = useUser()

  const [addGenre, { loading: isAddingGenre }] = useMutation(ADD_GENRE, {})

  const [removeGenre] = useMutation(REMOVE_GENRE, {})

  const earworm = client.readFragment<EarwormType>({
    id: `earworm:${props?.earworm?.id}`,
    fragment: gql`
      fragment addedearwormgenres on earworm {
        artist
        genres {
          name
        }
        id
        imageUrl
        trackName
      }
    `,
  })

  const [weFoundGenres] = useState(earworm?.genres.length || false)
  if (!earworm) {
    return null
  }

  const { artist, imageUrl, trackName } = earworm

  const handleRemoveTag = (genreToRemove: Genre) => {
    removeGenre({
      variables: {
        earwormId: earworm.id,
        genre: genreToRemove.name,
      },
    })
  }

  const handleAddNewGenre = (genreToAdd: Genre) => {
    addGenre({
      variables: {
        earwormId: earworm.id,
        genre: genreToAdd.name,
      },
    })
  }

  return (
    <div>
      <div className={previewContainerStyle}>
        <ImageFadeLoad alt={trackName} src={imageUrl} />
        <div className={previewMetaStyle}>
          <strong>{trackName}</strong>
          <span>{artist}</span>
        </div>
      </div>
      {weFoundGenres ? (
        <>
          <Twemoji
            className={styledTwmojiStyle}
            options={{ className: "twemoji", noWrapper: true }}
          >
            <p>🤖 We tagged it like this</p>
          </Twemoji>
          <small>
            <strong>Disagree?</strong> You can remove any tags that stink
          </small>
        </>
      ) : (
        <Twemoji
          className={styledTwmojiStyle}
          options={{ className: "twemoji", noWrapper: true }}
        >
          <strong
            style={{
              display: "block",
              marginTop: "3rem",
              marginBottom: "-1rem",
            }}
          >
            🎧 Help people discover this by tagging it
          </strong>
        </Twemoji>
      )}
      <div className={genreTagsStyle}>
        {earworm.genres.map((genre) => (
          <GenreTag key={genre.name} onClick={() => handleRemoveTag(genre)}>
            {genre.name}
            <Remove className={removeIconStyle} />
          </GenreTag>
        ))}
      </div>
      <AddGenres
        isAddingGenre={isAddingGenre}
        onAddNewGenre={handleAddNewGenre}
      />
      <div className={goNextStyle}>
        <h3>Go next..</h3>

        <div>
          <LinkButton buttonStyle="text" to="/">
            My Feed
          </LinkButton>

          {user && (
            <LinkButton buttonStyle="text" to={`/${user.profile.handle}`}>
              My Profile
            </LinkButton>
          )}
        </div>
      </div>
    </div>
  )
}
