import "src/pages/profile/ProfileHeader.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/pages/profile/ProfileHeader.css.ts.vanilla.css\",\"source\":\"LnllYnUxZzAgewogIGRpc3BsYXk6IGZsZXg7CiAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjsKICBhbGlnbi1pdGVtczogY2VudGVyOwogIHdpZHRoOiAxMDAlOwogIG1hcmdpbi10b3A6IDFyZW07CiAgdGV4dC1hbGlnbjogY2VudGVyOwp9Ci55ZWJ1MWcxIHsKICB3aWR0aDogMTAwJTsKfQoueWVidTFnMiB7CiAgbWFyZ2luLWJvdHRvbTogMnJlbTsKfQoueWVidTFnMyB7CiAgZm9udC1zaXplOiAxLjVyZW07CiAgbGluZS1oZWlnaHQ6IDEuMTI1OwogIGZvbnQtd2VpZ2h0OiA2MDA7CiAgY29sb3I6ICMzNjM2MzY7Cn0KLnllYnUxZzQgewogIGRpc3BsYXk6IGJsb2NrOwogIG1hcmdpbi10b3A6IDAuNHJlbTsKICBtYXJnaW4tYm90dG9tOiAxcmVtOwp9Ci55ZWJ1MWc1IHsKICBtYXJnaW4tYm90dG9tOiAxLjVyZW07Cn0KLnllYnUxZzYgewogIGRpc3BsYXk6IGZsZXg7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKICBtYXJnaW4tdG9wOiAwLjNyZW07CiAgY29sb3I6ICM0YTRhNGE7Cn0KLnllYnUxZzcgewogIG1hcmdpbi1yaWdodDogMC4ycmVtOwogIHdpZHRoOiAxcmVtOwogIGhlaWdodDogMXJlbTsKfQoueWVidTFnOCB7CiAgbWFyZ2luLXRvcDogMS41cmVtOwp9Ci55ZWJ1MWc5IHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiByb3c7CiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1hcm91bmQ7CiAgbWFyZ2luOiAxcmVtIDA7CiAgd2lkdGg6IDEwMCU7Cn0KLm5hbWVTdHlsZWRUd2Vtb2ppIHsKICB3aWR0aDogMS41cmVtOwogIGhlaWdodDogMS41cmVtOwp9Ci5iaW9TdHlsZWRUd2Vtb2ppIHsKICB3aWR0aDogMXJlbTsKICBoZWlnaHQ6IDFyZW07Cn0=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var bioStyle = 'yebu1g5';
export var editProfileButtonStyle = 'yebu1g2';
export var handleStyle = 'yebu1g4';
export var iconStyle = 'yebu1g7';
export var locationDetailsStyle = 'yebu1g6';
export var nameStyle = 'yebu1g3';
export var profileDetailsSyle = 'yebu1g0';
export var profileInfoStyle = 'yebu1g1';
export var toggleFollowButtonContainerStyle = 'yebu1g8';
export var valuesStyle = 'yebu1g9';