import React from "react"
import { useParams } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import { Helmet } from "react-helmet"

import { EARWORM_BASE } from "../../../fragments"
import FeedList from "../../../FeedList"
import LargeEarwormFeedItem from "../../../LargeEarwormFeedItem"
import MoreEarwormsByProfile from "./MoreEarwormsByProfile"

// Types
import { EarwormType } from "../../../types/earworm"

const PROFILE_EARWORM = gql`
  ${EARWORM_BASE}

  query ProfileEarworm($handle: String!, $earwormId: String!) {
    profileEarworm(handle: $handle, earwormId: $earwormId) {
      ...${EARWORM_BASE}
    }
  }
`

interface ParamsType {
  earwormId: string
  handle: string
}

type ProfileEarwormQueryData = {
  profileEarworm: EarwormType
}

export default function ProfileEarworm() {
  const { handle, earwormId } = useParams<ParamsType>()

  const { data } = useQuery<ProfileEarwormQueryData>(PROFILE_EARWORM, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { handle, earwormId },
  })

  const earworm = data?.profileEarworm

  if (!earworm) {
    return null
  }

  const { artist, imageUrl, profile, trackName } = earworm

  return (
    <>
      <Helmet>
        <title>{`@${handle} shared ${trackName} by ${artist} on Earworms`}</title>
        <meta
          property="og:title"
          content={`@${handle} shared ${trackName} by ${artist} on Earworms`}
        />
        <meta
          property="og:description"
          content={`@${handle} shared ${trackName} by ${artist} on Earworms`}
        />
        <meta property="og:image" content={imageUrl} />
        <meta
          property="og:url"
          content={`https://earworms.club/${handle}/earworm/${earwormId}`}
        />
        <meta
          name="twitter:title"
          content={`@${handle} shared ${trackName} by ${artist} on Earworms`}
        />
        <meta
          name="twitter:description"
          content={`@${handle} shared ${trackName} by ${artist} on Earworms`}
        />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <FeedList>
        <LargeEarwormFeedItem earworm={earworm} />
      </FeedList>

      <FeedList>
        <MoreEarwormsByProfile profile={profile} />
      </FeedList>
    </>
  )
}
