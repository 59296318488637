import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import Plausible from "plausible-tracker"

const { trackPageview } = Plausible()

export default function TrackPageView() {
  const currentLocation = useLocation()

  useEffect(() => {
    setTimeout(() => {
      trackPageview()
    }, 750)
  }, [currentLocation.pathname])

  return null
}
