// https://gist.github.com/atomiks/520f4b0c7b537202a23a3059d4eec908

// Will only render the `content` or `render` elements if the tippy is mounted to the DOM.
// Replace <Tippy /> with <LazyTippy /> component and it should work the same.

// I have removed the "render" code as not using it in Earworms right now

import React, { useState } from "react"
import Tippy, { TippyProps } from "@tippyjs/react"

export default function LazyTippy(props: TippyProps) {
  const [mounted, setMounted] = useState(false)

  const lazyPlugin = {
    fn: () => ({
      onShow: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  }

  const computedProps = { ...props }

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])]

  computedProps.content = mounted ? props.content : ""

  return <Tippy {...computedProps} />
}
