import React from "react"
import {
  bodyHasErrorStyle,
  bodyStyle,
  containerHasErrorStyle,
  containerStyle,
  headerHasErrorStyle,
  headerStyle,
} from "./MessageBox.css"

type MessageBoxProps = {
  children: React.ReactNode
  header: React.ReactNode
  isError?: boolean
}

export default function MessageBox(props: MessageBoxProps) {
  const { children, header, isError } = props

  return (
    <article
      className={`${
        !!isError
          ? `${containerStyle} ${containerHasErrorStyle}`
          : `${containerStyle}`
      }`}
    >
      <div
        className={`${
          !!isError ? `${headerStyle} ${headerHasErrorStyle}` : `${headerStyle}`
        }`}
      >
        {header}
      </div>
      <div
        className={`${
          !!isError ? `${bodyStyle} ${bodyHasErrorStyle}` : `${bodyStyle}`
        }`}
      >
        {children}
      </div>
    </article>
  )
}
