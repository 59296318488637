import React from "react"
import { Link, useLocation } from "react-router-dom"

import LabelValueBox from "./LabelValueBox"
import { labelValueBoxStyle, styledLinkStyle } from "./LinkLabelValueBox.css"

type LinkLabelValueBoxProps = {
  label: string
  to: string
  value?: string | number
}

export default function LinkLabelValueBox(props: LinkLabelValueBoxProps) {
  const location = useLocation<any>()

  const { label, to, value } = props

  if (location.pathname === to) {
    return (
      <LabelValueBox
        className={labelValueBoxStyle}
        label={label}
        value={value}
      />
    )
  }

  return (
    <Link className={styledLinkStyle} to={to}>
      <LabelValueBox label={label} value={value} />
    </Link>
  )
}
