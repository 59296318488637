import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { gql, useLazyQuery } from "@apollo/client"
import { Helmet } from "react-helmet"

import { useUser } from "../contexts/user-context"
import FeedList from "../FeedList"
import ProfileHeaderEdit from "./profile/ProfileHeaderEdit"

// Types
import { Profile } from "../types/profile"
import { feedTitleStyle } from "../FeedTitle.css"

const PROFILE = gql`
  query Profile($handle: String!) {
    profile(handle: $handle) {
      bio
      handle
      id
      isFollowing
      location {
        country
        id
        name
      }
      name
      profileImage {
        filename
        id
      }
    }
  }
`

type ProfileQueryData = {
  profile: Profile
}

export default function Welcome() {
  const history = useHistory()
  const { user } = useUser()

  const [fetchProfile, { data }] = useLazyQuery<ProfileQueryData>(PROFILE, {
    fetchPolicy: "cache-and-network",
  })

  useEffect(() => {
    if (!user?.profile?.handle) {
      return
    }
    fetchProfile({
      variables: {
        handle: user?.profile?.handle,
      },
    })
  }, [fetchProfile, user])

  const profile = data?.profile

  if (!profile) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>{`Welcome! · Earworms`}</title>
      </Helmet>

      <FeedList>
        <h4 className={feedTitleStyle}>
          Hi @{profile?.handle}, Welcome to Earworms!
        </h4>
        <p>Complete your Earworms profile so you can share your first track.</p>
        <ProfileHeaderEdit
          onComplete={() => {
            history.push(`/${profile?.handle}`)
          }}
          profile={profile}
        />
      </FeedList>
    </>
  )
}
