import React from "react"
import { gql, useQuery } from "@apollo/client"
import { Helmet } from "react-helmet"

import FeedList from "../../FeedList"
import { GenreTagLink as GenreTag } from "../../GenreTag"
import {
  genresHeaderContainer,
  genresTagContainer,
  genresTitleStyle,
} from "./GenresPage.css"

const GENRES = gql`
  query Genres {
    genres {
      name
    }
  }
`

type GenresQueryData = {
  genres: Array<{ name: string }>
}

export default function GenresPage() {
  const { data } = useQuery<GenresQueryData>(GENRES, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  })

  return (
    <>
      <Helmet>
        <title>Genres · Earworms</title>
        <meta
          property="og:description"
          content={`Browse music genres shared on Earworms`}
        />
        <meta property="og:image" content="/earworms_square.png" />
      </Helmet>

      <FeedList>
        <div className={genresHeaderContainer}>
          <h2 className={genresTitleStyle}>Genres</h2>
          <p>{`Browse music genres shared on Earworms`}</p>
        </div>

        <div className={genresTagContainer}>
          {data?.genres?.map((genre) => (
            <GenreTag
              key={genre.name}
              to={`/genres/${genre.name.toLowerCase().replace(/ /g, "_")}`}
            >
              {genre.name.toLowerCase()}
            </GenreTag>
          ))}
        </div>
      </FeedList>
    </>
  )
}
