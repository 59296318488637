import React from "react"
import { Link } from "react-router-dom"
import { genreContainerStyle } from "./GenreTag.css"

export default function GenreTag({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick?: React.DOMAttributes<HTMLDivElement>["onClick"]
}) {
  return (
    <div className={genreContainerStyle} onClick={onClick}>
      {children}
    </div>
  )
}

export function GenreTagLink({
  children,
  to,
}: {
  children: React.ReactNode

  to: string
}) {
  return (
    <Link className={genreContainerStyle} to={to}>
      {children}
    </Link>
  )
}
