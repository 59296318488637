import React from "react"

import MainNavLinks from "./MainNavLinks"
import { sideBarContainerStyle, sideBarWrapperStyle } from "./SideBar.css"

export default function SideBar() {
  return (
    <div className={sideBarWrapperStyle}>
      <div className={sideBarContainerStyle}>
        <MainNavLinks />
      </div>
    </div>
  )
}
