import React from "react"
import {
  baseModalBackgroundStyle,
  baseModalCloseButtonStyle,
  baseModalCloseIconStyle,
  baseModalContainerStyle,
  baseModalContentBoxStyle,
  baseModalContentStyle,
} from "./BaseModal.css"

import FeedList from "./FeedList"

// Icon
import { ReactComponent as Close } from "./icons/close.svg"

type BaseModalProps = {
  children: React.ReactNode
  onClose: () => void
}

export default function BaseModal(props: BaseModalProps) {
  const { children, onClose } = props

  return (
    <div className={baseModalContainerStyle}>
      <div className={baseModalBackgroundStyle} onClick={onClose} />

      <div className={baseModalContentStyle}>
        <FeedList centre>
          <div className={baseModalContentBoxStyle}>{children}</div>
        </FeedList>
      </div>

      <button
        aria-label="close modal"
        className={baseModalCloseButtonStyle}
        onClick={onClose}
      >
        <Close className={baseModalCloseIconStyle} />
      </button>
    </div>
  )
}
