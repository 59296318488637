import React, { useContext, useEffect, useState } from "react"
import { gql, useLazyQuery } from "@apollo/client"
import Cookies from "js-cookie"
import * as Sentry from "@sentry/react"

import { EARWORM_BASE } from "../fragments"

// Types
import { User } from "../types/user"

const AUTHENTICATE = gql`
  ${EARWORM_BASE}

  {
    authenticate {
      profile {
        handle
        id
        isConnectedToSpotify
        isFollowing
        location {
          country
          id
          name
        }
        profileImage {
          filename
          id
        }
        thisWeeksEarworm {
          ...EarwormBaseData
        }
      }
    }
  }
`

type UserContextType = {
  isAuthenticatingUser: boolean
  refetchUser?: () => void
  setUser: (arg0: User) => void
  user: User | null
}

const UserContext = React.createContext<UserContextType>({
  isAuthenticatingUser: false,
  refetchUser: () => {},
  setUser: () => {},
  user: null,
})

type Props = {
  children: React.ReactNode
}

export default function UserContextComponent(props: Props) {
  const { children } = props

  const [isAuthenticatingUser, setIsAuthenticatingUser] = useState(true)
  const [user, setUser] = useState<User | null>(null)

  const [authenticate, { client, refetch }] = useLazyQuery(AUTHENTICATE, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (!data?.authenticate) {
        return
      }

      setUser(data.authenticate)
      setIsAuthenticatingUser(false)

      // Identify user with third parties
      Sentry.setUser({ username: data.authenticate.profile.handle })
    },
    onError: ({ networkError }) => {
      setIsAuthenticatingUser(false)

      // @ts-ignore
      if (networkError?.statusCode === 401) {
        Cookies.remove("token", { domain: "earworms.club" })
        localStorage.removeItem("hideWelcomeCard")
        setUser(null)
        Sentry.setUser(null)
        if (client) {
          client.resetStore()
        }
        window.location.href = "/signin"
      }
    },
  })

  useEffect(() => {
    // Attempt to reauthenticate user on page load
    const token = Cookies.get("token")
    if (!token) {
      setIsAuthenticatingUser(false)
      return
    }

    setIsAuthenticatingUser(true)
    authenticate()
  }, [authenticate])

  return (
    <UserContext.Provider
      value={{ isAuthenticatingUser, user, refetchUser: refetch, setUser }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
