import React from "react"
import Plausible from "plausible-tracker"

import { useUser } from "./contexts/user-context"
import BaseModal from "./BaseModal"
import ExternalLinkButton from "./ExternalLinkButton"
import LinkButton from "./LinkButton"

// Styles
import {
  descriptionStyle,
  openInSpotifyButtonStyle,
  spotifyIconStyle,
  titleStyle,
} from "./UnconnectedListenToTrackModal.css"

const { trackEvent } = Plausible()

type ModalProps = {
  onClose: () => void
  spotifyId?: string
}

export default function UnconnectedListenToTrackModal(props: ModalProps) {
  const { onClose, spotifyId } = props

  const { user } = useUser()

  const handleInSpotifyClick = () => {
    // Track a play in Spotify
    trackEvent("Played an Earworm", { props: { label: "On Spotify" } })
  }

  return (
    <BaseModal onClose={onClose}>
      <img
        alt="Spotify logo"
        className={spotifyIconStyle}
        src="spotify_icon_green.png"
      />

      {user ? (
        <p className={titleStyle}>Hey @{user?.profile?.handle},</p>
      ) : (
        <p className={titleStyle}>Hey stranger,</p>
      )}

      <p className={descriptionStyle}>
        {user
          ? "To listen to this track we need to connect your Earworms account with your Spotify account"
          : "You need to sign in to listen"}
      </p>
      <p className={descriptionStyle}>
        Otherwise you can open this track in Spotify
      </p>

      {user ? (
        <ExternalLinkButton
          buttonStyle="link"
          href={`https://accounts.spotify.com/authorize?client_id=56e0759e23dc43aeb3f45b764f0075c7&response_type=code&redirect_uri=${process.env.REACT_APP_SPOTIFY_CALLBACK_URI}&scope=playlist-modify-public%20playlist-modify-private%20user-modify-playback-state%20streaming%20user-read-email%20user-read-private`}
        >
          Connect to Spotify
        </ExternalLinkButton>
      ) : null}

      {!user ? (
        <LinkButton buttonStyle="link" onClick={onClose} to="/signin">
          Sign in to Earworms
        </LinkButton>
      ) : null}

      {spotifyId ? (
        <ExternalLinkButton
          buttonStyle="text"
          className={openInSpotifyButtonStyle}
          href={`https://open.spotify.com/track/${spotifyId}`}
          onClick={handleInSpotifyClick}
          target="_blank"
        >
          Open in Spotify
        </ExternalLinkButton>
      ) : null}
    </BaseModal>
  )
}
