import React from "react"
import { externalLinkButtonStyle } from "./ExternalLinkButton.css"

type ExternalLinkButtonProps = {
  buttonStyle?: "link" | "primary" | "text"
  children?: React.ReactNode
  className?: string
  href: string
  onClick?: () => void
  target?: string
}

export default function LinkButton(props: ExternalLinkButtonProps) {
  const { buttonStyle, children, className, href, onClick, target } = props

  return (
    <a
      className={`${externalLinkButtonStyle} ${className} ${buttonStyle}`}
      href={href}
      onClick={onClick}
      target={target}
    >
      {children}
    </a>
  )
}
