import React from "react"
import Twemoji from "react-twemoji"

import { useUser } from "../../contexts/user-context"
import Button from "../../Button"
import LinkLabelValueBox from "../../LinkLabelValueBox"
import MemberOfTheMonth from "./MemberOfTheMonth"
import ProfileGenreTags from "./ProfileGenreTags"
import ProfileImage from "./ProfileImage"
import ProfileInfoBox from "./ProfileInfoBox"
import ToggleFollowButton from "./ToggleFollowButton"
import {
  bioStyle,
  editProfileButtonStyle,
  handleStyle,
  iconStyle,
  locationDetailsStyle,
  nameStyle,
  profileDetailsSyle,
  profileInfoStyle,
  toggleFollowButtonContainerStyle,
  valuesStyle,
} from "./ProfileHeader.css"

// Icons
import { ReactComponent as Pencil } from "../../icons/pencil.svg"
import { ReactComponent as Pin } from "../../icons/location.svg"

// Types
import { Profile } from "../../types/profile"

type ProfileHeaderProps = {
  onStartEditing: () => void
  profile: Profile
}

export default function ProfileHeader(props: ProfileHeaderProps) {
  const { user } = useUser()

  const { onStartEditing, profile } = props
  const {
    bio,
    followerCount,
    followingCount,
    genres,
    handle,
    location,
    profileImage,
    name,
    totalEarworms,
  } = profile

  return (
    <section className={profileDetailsSyle}>
      {profile.id === user?.profile?.id ? (
        <Button
          buttonStyle="text"
          className={editProfileButtonStyle}
          onClick={onStartEditing}
          type="button"
        >
          <Pencil className={iconStyle} /> Edit My Profile
        </Button>
      ) : null}

      {profileImage && (
        <ProfileImage
          alt={`Avatar for profile of ${handle}`}
          src={`/img256/${profileImage.filename}`}
          // src="https://earworms.club/img256/97Sb2WwQMwN9jOGiR9SDq.jpg"
        />
      )}

      <div className={profileInfoStyle}>
        <Twemoji options={{ className: "nameStyledTwemoji", noWrapper: true }}>
          <h3 className={nameStyle}>{name}</h3>
        </Twemoji>

        <em className={handleStyle}>{`@${handle}`}</em>

        {bio && (
          <Twemoji options={{ className: "bioStyledTwemoji", noWrapper: true }}>
            <p className={bioStyle}>{bio}</p>
          </Twemoji>
        )}

        {location?.name && (
          <ProfileInfoBox title="Location">
            <div className={locationDetailsStyle}>
              <Pin className={iconStyle} />
              {location?.name}
              {location?.country ? `, ${location.country}` : null}
            </div>
          </ProfileInfoBox>
        )}
      </div>

      {genres?.length ? <ProfileGenreTags genres={genres} /> : null}

      {handle === "loz" ? <MemberOfTheMonth /> : null}

      <div className={toggleFollowButtonContainerStyle}>
        <ToggleFollowButton profile={props.profile} />
      </div>

      <div className={valuesStyle}>
        <LinkLabelValueBox
          label="Earworms"
          to={`/${handle}`}
          value={totalEarworms}
        />
        <LinkLabelValueBox
          label="Followers"
          to={`/${handle}/followers`}
          value={followerCount || 0}
        />
        <LinkLabelValueBox
          label="Following"
          to={`/${handle}/following`}
          value={followingCount || 0}
        />
      </div>
    </section>
  )
}
