import React from "react"
import { Link } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import formatRelative from "date-fns/formatRelative"
import Twemoji from "react-twemoji"

import { EARWORM_BASE } from "./fragments"
import ImageFadeLoad from "./ImageFadeLoad"
import LargeEarwormFeedItemFooter from "./LargeEarwormFeedItemFooter"

// Types
import { EarwormType } from "./types/earworm"

// Styles
import {
  artistNameStyle,
  avatarStyle,
  containerStyle,
  coverArtStyle,
  detailsStyle,
  dotSeperatorStyle,
  emojiStyle,
  linkStyle,
  messageStyle,
  shareDateLinkStyle,
  trackNameStyle,
  userDetailsStyle,
  userHandleStyle,
  usernameStyle,
} from "./LargeEarwormFeedItem.css"

type LargeEarwormFeedItemProps = {
  earworm: EarwormType
}

export default function LargeEarwormFeedItem(props: LargeEarwormFeedItemProps) {
  const client = useApolloClient()

  const earworm = client.readFragment({
    id: `earworm:${props?.earworm?.id}`,
    fragment: EARWORM_BASE,
  })

  if (!earworm) {
    return null
  }

  const { artist, createdAt, imageUrl, message, trackName, profile } = earworm
  const { name, profileImage, handle } = profile

  return (
    <div className={containerStyle}>
      <div className={detailsStyle}>
        <div className={userDetailsStyle}>
          {profileImage && (
            <Link to={`/${handle}`}>
              <ImageFadeLoad
                alt={`Avatar for profile of ${handle}`}
                className={avatarStyle}
                src={`/img256/${profileImage.filename}`}
                // src="https://earworms.club/img256/97Sb2WwQMwN9jOGiR9SDq.jpg"
              />
            </Link>
          )}
          <div>
            <Twemoji
              options={{ className: `twemoji ${emojiStyle}`, noWrapper: true }}
            >
              <p>
                <Link className={linkStyle} to={`/${handle}`}>
                  <strong className={usernameStyle}>{name}</strong>

                  <span className={dotSeperatorStyle}>{" · "}</span>
                  <small className={userHandleStyle}>@{handle}</small>
                </Link>
              </p>
            </Twemoji>
            <Link
              className={shareDateLinkStyle}
              to={`/${handle}/earworm/${earworm.id}`}
            >
              <small>
                {formatRelative(new Date(parseInt(createdAt, 10)), Date.now())}
              </small>
            </Link>
          </div>
        </div>
        {message && (
          <Twemoji
            options={{ className: `twemoji ${emojiStyle}`, noWrapper: true }}
          >
            <strong className={messageStyle}>{message}</strong>
          </Twemoji>
        )}
        <p className={trackNameStyle}>{trackName}</p>
        <p className={artistNameStyle}>{artist}</p>
      </div>
      <ImageFadeLoad
        alt={`Cover art for ${trackName} by ${artist}`}
        className={coverArtStyle}
        src={imageUrl}
      />
      <LargeEarwormFeedItemFooter earworm={earworm} />
    </div>
  )
}
