import { gql } from "@apollo/client"

export const EARWORM_BASE = gql`
  fragment EarwormBaseData on earworm {
    artist
    comments {
      createdAt
      id
      profile {
        handle
        id
        name
        profileImage {
          filename
          id
        }
      }
      text
    }
    createdAt
    emojiReactions {
      count
      earwormId
      emoji
      profiles {
        handle
        id
        name
      }
    }
    genres {
      name
    }
    handle
    id
    imageUrl
    message
    spotifyId
    trackName
    profile {
      handle
      id
      name
      profileImage {
        filename
        id
      }
    }
  }
`
