import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  InMemoryCache,
} from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import Plausible from "plausible-tracker"

import App from "./App"
import ScrollToTop from "./ScrollToTop"
import TrackPageView from "./TrackPageView"
import "./index.css"

import "react-image-crop/dist/ReactCrop.css"

Sentry.init({
  dsn:
    "https://4261f60e156f463facdccbe6592d0294@o463926.ingest.sentry.io/5469556",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

const plausible = Plausible({
  domain: "earworms.club",
})

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
  credentials: "include",
})

const client = new ApolloClient({
  link: (uploadLink as unknown) as ApolloLink,
  cache: new InMemoryCache({
    typePolicies: {
      Earworm: {
        fields: {
          genres: {
            merge(existing, incoming) {
              return incoming
            },
          },
        },
      },
      EmojiReaction: {
        keyFields: ["earwormId", "emoji"],
      },
      Genre: {
        keyFields: ["name"],
      },
    },
  }).restore(window.__APOLLO_STATE__),
  ssrForceFetchDelay: 100,
})

ReactDOM.hydrate(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop />
        <TrackPageView />
        <App />
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
