import React from "react"
import { gql, useQuery } from "@apollo/client"
import { Helmet } from "react-helmet"

import FeedList from "../../FeedList"
import PlaylistListItem from "./PlaylistListItem"

// Types
import { Playlist } from "../../types/playlist"
import { feedTitleStyle } from "../../FeedTitle.css"

const PLAYLISTS = gql`
  query Playlists {
    playlists {
      earworms {
        artist
        id
        profile {
          handle
          id
          profileImage {
            filename
            id
          }
        }
        trackName
      }
      id
      name
      spotifyId
      trackCount
    }
  }
`

type PlaylistsQueryData = {
  playlists: Array<Playlist>
}

//
// TODO: Pass in playlist id and get it from the cache
//       inside <PlaylistListItem />
//

export default function PlaylistsPage() {
  const { data } = useQuery<PlaylistsQueryData>(PLAYLISTS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  })

  return (
    <>
      <Helmet>
        <title>{`Playlists · Earworms`}</title>
      </Helmet>

      <FeedList>
        <h4 className={feedTitleStyle}>Your Playlists</h4>
        {data?.playlists?.map((playlist) => (
          <PlaylistListItem key={playlist.id} playlist={playlist} />
        ))}
      </FeedList>
    </>
  )
}
