import React from "react"
import { Route, Switch } from "react-router-dom"

import GenrePage from "./GenrePage"
import GenresPage from "./GenresPage"

export default function Genres() {
  return (
    <Switch>
      <Route path="/genres/:genre">
        <GenrePage />
      </Route>
      <Route path="/genres">
        <GenresPage />
      </Route>
    </Switch>
  )
}
