import React from "react"
import { textStyle, valueStyle } from "./LabelValueBox.css"

type LabelValueBoxProps = {
  className?: string
  label: string
  value?: string | number
}

export default function LabelValueBox(props: LabelValueBoxProps) {
  const { className, label, value } = props

  return (
    <div className={className}>
      <p className={valueStyle}>{value}</p>
      <p className={textStyle}>{label}</p>
    </div>
  )
}
