import "src/LargeEarwormFeedItem.css.ts.vanilla.css!=!../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/LargeEarwormFeedItem.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE5VU23KDIBB9z1cwk+kjFjUmqX4NChpaBAdJNe303wt4KTY2TYYHZ5bdPcs5ewzyiNPuFYHPDQA1VhUTMJdayzoFkaJ1ZsK5VIQqqDBh5zYFKIiS8aZjRJ9SECL0lG2+NsHQLHTNhipz2fSglZwRsC2RPV7LCUlIQb2wlg1UrDrpNUwvh9NyNaXBhDBR2dh+DM7DRW44wtqG40sKSk57W2K/kDBFC82kSIGSXXbNSLjsFfusuYEt5GGdtsRyNFMWj0knOpTFy867h6bEnFUCMk1rg1NQoamyYU17DQktpMJD+kDzD0h6ku9UOair3LMww3O2KEhcaiG5NLpu47093vXeo8MQYRVZvuqwDjWpPzVGCPlFj055dKmlFBq27INaSY6HZKB7gthhe7yiF19Ks1tG62BaJ69VtGv6OdSN2uWSExu0c8BJ0DAIoyS7RRde89wqanj0UFt94SbGtFG98NrlnuumZbzHe7dyrMHu6fKvW8e0vwy7+I+YfW4bs+HQiWyiz6H3zGLpDCYc6zmXxduKBlaCuZS40gnrlwFHa38Du/80FxAFAAA=\"}!../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var artistNameStyle = 'b2lewja';
export var avatarStyle = 'b2lewj3';
export var containerStyle = 'b2lewj0';
export var coverArtStyle = 'b2lewjb';
export var detailsStyle = 'b2lewj1';
export var dotSeperatorStyle = 'b2lewj6';
export var emojiStyle = 'b2lewjd';
export var linkStyle = 'b2lewj4';
export var messageStyle = 'b2lewjc';
export var shareDateLinkStyle = 'b2lewj7';
export var trackNameStyle = 'b2lewj9';
export var userDetailsStyle = 'b2lewj2';
export var userHandleStyle = 'b2lewj8';
export var usernameStyle = 'b2lewj5';