import React, { useState } from "react"
import Twemoji from "react-twemoji"

import Button from "../../Button"
import Input from "../../Input"

// Styles
import {
  addGenreButtonStyle,
  containerStyle,
  inputContainerStyle,
  twemojiStyle,
} from "./AddGenres.css"

// Types
import { Genre } from "../../types/genre"

type AddGenresProps = {
  isAddingGenre: boolean
  onAddNewGenre: (newGenre: Genre) => void
}

export default function AddGenres(props: AddGenresProps) {
  const { isAddingGenre, onAddNewGenre } = props

  const [currentNewTag, setCurrentNewTag] = useState("")

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setCurrentNewTag(value)
  }

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    const newTag = currentNewTag.trim()

    // Empty "add new tag" input box
    setCurrentNewTag("")

    // Add this new tag to the list
    onAddNewGenre({ id: "null", name: newTag })
  }

  return (
    <div className={containerStyle}>
      <form onSubmit={handleSubmit}>
        <div className={inputContainerStyle}>
          <Input
            autoFocus
            onChange={handleChange}
            placeholder="Add tag"
            value={currentNewTag}
          />
          <Button
            buttonStyle="link"
            className={addGenreButtonStyle}
            isLoading={isAddingGenre}
            type="submit"
          >
            Add tag
          </Button>
        </div>
      </form>

      <small>
        Add more tags for this track. <br />
        <em>
          Indie? Upbeat? Synth? Nostalgic? Classical?{" "}
          <Twemoji
            className={twemojiStyle}
            options={{ className: "twemoji", noWrapper: true }}
          >
            {" "}
            😶?
          </Twemoji>
        </em>
      </small>
    </div>
  )
}
