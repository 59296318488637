import React from "react"
import Autosuggest from "react-autosuggest"

import Input from "./Input"
import {
  autocompleteContainerStyle,
  suggestionItemIsHighlightedStyle,
  suggestionItemStyle,
  suggestionsContainerStyle,
} from "./AutoComplete.css"

// TODO: make type more generic
type Suggestion = {
  country?: string
  id?: string
  name: string
  state?: string
}

/* Return the suggestion name */
const getSuggestionValue = (suggestion: Suggestion) => suggestion.name

const renderInputComponent = (isSearching: boolean) => (inputProps: any) => {
  return <Input isLoading={isSearching} {...inputProps} />
}

const renderSuggestionsContainer = ({
  containerProps,
  children,
  query,
}: any) => {
  return (
    <div className={suggestionsContainerStyle} {...containerProps}>
      {children}
    </div>
  )
}

type AutoCompleteProps = {
  isSearching?: boolean
  onChange: (event: React.FormEvent<any>) => void
  onSuggestionSelected: (
    event: React.FormEvent<any>,
    { suggestion }: { suggestion: Suggestion }
  ) => void
  onSuggestionsFetchRequested: (event: { value: string }) => void
  renderSuggestion: (suggestion: Suggestion) => React.ReactNode
  suggestions: Array<Suggestion>
  value: string
}

export default function AutoComplete(props: AutoCompleteProps) {
  const {
    isSearching = false,
    onChange,
    onSuggestionSelected,
    onSuggestionsFetchRequested,
    renderSuggestion,
    suggestions,
    value,
  } = props

  const handleRenderSuggestion = (
    suggestion: Suggestion,
    { isHighlighted }: { isHighlighted: boolean }
  ) => {
    return (
      <div
        className={`${
          isHighlighted
            ? `${suggestionItemStyle} ${suggestionItemIsHighlightedStyle}`
            : `${suggestionItemStyle}`
        }`}
      >
        {renderSuggestion(suggestion)}
      </div>
    )
  }

  return (
    <div className={autocompleteContainerStyle}>
      <Autosuggest
        inputProps={{
          autoComplete: "off",
          name: "location",
          onChange,
          value,
        }}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderInputComponent={renderInputComponent(isSearching)}
        renderSuggestion={handleRenderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        suggestions={suggestions}
      />
    </div>
  )
}
