import "src/LargeEarwormFeedItemFooter.css.ts.vanilla.css!=!../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/LargeEarwormFeedItemFooter.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE61W7W7bIBT9v6dAqSptP7BI2iRtqu1Fpm0iBjt0NlgYN0mnvfsuYBJwHG9VJ6uNfLncj3MOF2c/5mVV8OeSoF8fEKqpLoXERjUbhOeL5vAExoYyJmTprSS7Wy81r+3CVmnG9QbNmwNqVSUYuimIfc6LfpNUkke2rTJG1ViLcmewpkx0rQ28SOMGt4oXI16/P2Sh9LkrnYm2qehxg4qKu7LtL95rCvntf2sqtWC4pK6NkKzvDkxgXLno/erpPcm2SLMJWQnJcUhKK1FKLAyvodicS8O1NT93rRHFEecKLNLESzHkI1VZbFeeh0u4jaaybaiGYBFuuaoUuI0vjsJ9wO2OMrU/M1VAobgVrxzS9W6uzx23pIExW1rbC9dG5LTCrm/IqRzO+x0ggCF5zm3IAL/hB4MZz5WmRigZ6YLmP0utOslC8Te8sI9dzDvdWlOjRMCsa6GTllc8NyFIwhBklpGePbeLIZN3ziUF7YaQ9bIoEr/7ScadzJjQUIvrSav9hBAa1Yrej1eAwguPNGCVvkG0M8qBKJjZ2XSwgVZJRctxxV9VWbI1M3teq2fhYvRJ5j06CJ0JvsBrNXHOIgAAya6W45K7OHPhoJ1myisWkvHDBt0TMhAiyR4npHjWkGPRbQ6kFgNG1773CZX2ej4Tl4S6KuUox4PLMc63hcyJEsHZyT8uyC3C0N49tPdpECQzommOmGrQFRqEpFsYA51xIb12iJ8UgdV+cJyQcu9j4WEEfGXUUAzU5ryGrr9/nsFhnn1DX9IS/JGxgxmyvSGY3zMez8++icY3W14ozZ3zSdyz2dNVNP5lErbmWAHxbpK+E5W4vgAOXnvwe2KixD07DzDG7d/gtgxFn8498vVDghoruDPFSZd+3L6Xgrh4f/H/rXISar+8rN9QvN8xrD+9ZoOQCblNHB/jrxXt1X0eL2HXw0D+/fu1qUCvXBnRvFok52tB0gThPSS4W9knybGN7xzL9uAjaKCF9NvnaQKR/P8G/gO9DRn6GAoAAA==\"}!../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var largeEarwormFeedItemFooterButtonIsCurrentUserStyles = '_1glfejg3';
export var largeEarwormFeedItemFooterButtonStyles = '_1glfejg2';
export var largeEarwormFeedItemFooterContainerStyles = '_1glfejg0';
export var largeEarwormFeedItemFooterEmojiButtonContainerStyles = '_1glfejg8';
export var largeEarwormFeedItemFooterEmojiButtonSectionStyles = '_1glfejg4';
export var largeEarwormFeedItemFooterEmojiReactionTwemojiStyles = '_1glfejg5';
export var largeEarwormFeedItemFooterEmojisStyles = '_1glfejg1';
export var largeEarwormFeedItemFooterOpenTrackButtonStyles = '_1glfejgc';
export var largeEarwormFeedItemFooterPlayIconStyles = '_1glfejg9';
export var largeEarwormFeedItemFooterPlayTrackButtonStyles = '_1glfejgb';
export var largeEarwormFeedItemFooterReactionProfileLinkStyles = '_1glfejg7';
export var largeEarwormFeedItemFooterReactionProfileLinksStyles = '_1glfejg6';
export var largeEarwormFeedItemFooterSmileIconStyles = '_1glfejga';