import React from "react"

import LazyTippy from "./LazyTippy"

// Icons
import { ReactComponent as PlayQueue } from "./icons/play_queue.svg"

// Styles
import {
  artistNameStyle,
  containerStyle,
  playQueueButtonIconStyle,
  queueButtonStyle,
  trackNameStyle,
  trackStyle,
} from "./TrackQueueModal.css"

// Types
import { EarwormType } from "./types/earworm"

type TrackQueueModalProps = {
  tracks: Array<EarwormType>
}

export default function TrackQueueModal(props: TrackQueueModalProps) {
  return (
    <LazyTippy
      animation={false}
      arrow={true}
      content={
        <div className={containerStyle}>
          {props.tracks.map((earworm) => (
            <div key={earworm.id} className={trackStyle}>
              <span className={trackNameStyle}>{earworm.trackName}</span>
              {" - "}
              <span className={artistNameStyle}>{earworm.artist}</span>
            </div>
          ))}
        </div>
      }
      trigger="click"
    >
      <button className={queueButtonStyle}>
        <PlayQueue className={playQueueButtonIconStyle} />
      </button>
    </LazyTippy>
  )
}
