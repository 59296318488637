import React from "react"
import ImageFadeLoad from "../../ImageFadeLoad"
import { profileImageStyle } from "./ProfileImage.css"

export default function ProfileImage({
  alt,
  src,
}: {
  alt: string
  src: string
}) {
  return <ImageFadeLoad alt={alt} className={profileImageStyle} src={src} />
}
