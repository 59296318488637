import React from "react"

import FeedList from "../../FeedList"
import { feedTitleStyle } from "../../FeedTitle.css"
import LargeEarwormFeedItem from "../../LargeEarwormFeedItem"

// Types
import { EarwormType } from "../../types/earworm"
import { Profile } from "../../types/profile"
import { explosionContainerStyle } from "./ProfileEarwormsList.css"

type ProfileEarwormsListProps = {
  profile: Profile
}

export default function ProfileEarwormsList(props: ProfileEarwormsListProps) {
  const { profile } = props
  const { recentEarworms, thisWeeksEarworm } = profile

  const recentEarwormsWithoutThisWeek = recentEarworms.filter(
    (recentEarworm) => recentEarworm.id !== thisWeeksEarworm?.id
  )

  return (
    <>
      {thisWeeksEarworm ? (
        <FeedList centre>
          <div className={explosionContainerStyle}>
            <h4 className={feedTitleStyle}>This Weeks Earworm</h4>
          </div>
          <LargeEarwormFeedItem earworm={thisWeeksEarworm} />
        </FeedList>
      ) : null}

      {recentEarwormsWithoutThisWeek?.length > 0 ? (
        <FeedList centre>
          <h4 className={feedTitleStyle}>Recent Earworms</h4>
          {recentEarwormsWithoutThisWeek.map((earworm: EarwormType) => (
            <LargeEarwormFeedItem key={earworm.id} earworm={earworm} />
          ))}
        </FeedList>
      ) : null}
    </>
  )
}
