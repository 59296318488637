import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import { useUser } from "./contexts/user-context"
import Button from "./Button"
import LinkButton from "./LinkButton"
import MobileNav from "./MobileNav"

// Icons
import { ReactComponent as Close } from "./icons/close.svg"
import { ReactComponent as Menu } from "./icons/menu.svg"

// Styles
import {
  buttonLinkStyle,
  buttonsStyle,
  containerStyle,
  contentStyle,
  menuIconStyle,
  mobileMenuButtonStyle,
  navLink,
  navTitle,
} from "./Header.css"

export default function Header() {
  const { pathname } = useLocation()
  const { isAuthenticatingUser, user } = useUser()

  const [showMobileNav, setShowMobileNav] = useState(false)

  useEffect(() => {
    // Close the mobile nav when the path changes
    setShowMobileNav(false)
  }, [pathname])

  const handleMenuIconClick = () => {
    setShowMobileNav((v) => !v)
  }

  const renderHeaderButtons = () => {
    if (!user) {
      return (
        <>
          <LinkButton buttonStyle="primary" to="/join">
            <strong>Join</strong>
          </LinkButton>

          <LinkButton className={buttonLinkStyle} to="/signin">
            Sign in
          </LinkButton>

          <Button
            className={mobileMenuButtonStyle}
            onClick={handleMenuIconClick}
          >
            {showMobileNav ? (
              <Close className={menuIconStyle} />
            ) : (
              <Menu className={menuIconStyle} />
            )}
          </Button>
        </>
      )
    }

    return (
      <>
        <LinkButton to={`/share`}>Share</LinkButton>

        <Button className={mobileMenuButtonStyle} onClick={handleMenuIconClick}>
          {showMobileNav ? (
            <Close className={menuIconStyle} />
          ) : (
            <Menu className={menuIconStyle} />
          )}
        </Button>
      </>
    )
  }

  return (
    <nav
      className={containerStyle}
      role="navigation"
      aria-label="main navigation"
    >
      <div className={contentStyle}>
        <Link className={navLink} to="/">
          <h2 className={navTitle}>Earworms</h2>
        </Link>

        <div className={buttonsStyle}>
          {!isAuthenticatingUser ? renderHeaderButtons() : null}
        </div>
      </div>

      {showMobileNav ? <MobileNav /> : null}
    </nav>
  )
}
