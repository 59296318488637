import React from "react"
import { paragraphIsDangerStyle, paragraphStyle } from "./HelpText.css"

type HelpTextProps = {
  children: React.ReactNode
  isDanger?: boolean
}

export default function HelpText({ children, isDanger }: HelpTextProps) {
  return (
    <p
      className={`${
        isDanger
          ? `${paragraphStyle} ${paragraphIsDangerStyle}`
          : `${paragraphStyle}`
      }`}
    >
      {children}
    </p>
  )
}
