import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"

import { useUser } from "../../contexts/user-context"
import Button from "../../Button"
import ExternalLinkButton from "../../ExternalLinkButton"
import Modal from "../../Modal"
import PlaylistEarwormItem from "./PlaylistEarwormItem"

// Styles
import {
  containerStyle,
  metaDataClickableStyle,
  metaDataContainerStyle,
  metaDataStyle,
  playlistEarwormItemContainerStyle,
  playlistNameStyle,
} from "./PlaylistItem.css"

// Types
import { Playlist } from "../../types/playlist"

const CREATE_SPOTIFY_PLAYLIST = gql`
  mutation createSpotifyPlaylistFromPlaylist($playlistId: String!) {
    createSpotifyPlaylistFromPlaylist(playlistId: $playlistId) {
      playlist {
        id
        name
        spotifyId
      }
    }
  }
`

type PlaylistListItemProps = {
  playlist: Playlist
}

export default function PlaylistListItem(props: PlaylistListItemProps) {
  const { playlist } = props

  const { user } = useUser()

  const [showConnectToSpotifyModal, setShowConnectToSpotifyModal] = useState(
    false
  )
  const [showTracks, setShowTracks] = useState(false)

  const [createSpotifyPlaylist, { loading }] = useMutation(
    CREATE_SPOTIFY_PLAYLIST,
    {
      onCompleted(data) {
        const { createSpotifyPlaylistFromPlaylist } = data
        const { playlist } = createSpotifyPlaylistFromPlaylist

        // Playlist has been created so open it
        window.open(`https://open.spotify.com/playlist/${playlist.spotifyId}`)
      },
    }
  )

  const handleNoSpotifyIdClick = () => {
    if (!user?.profile.isConnectedToSpotify) {
      setShowConnectToSpotifyModal(true)
      return
    }

    if (!playlist.spotifyId) {
      // Instruct API to create the Playlist
      createSpotifyPlaylist({ variables: { playlistId: playlist.id } })
    }
  }

  if (!user) {
    return null
  }

  return (
    <>
      <div className={containerStyle}>
        <p className={playlistNameStyle}>{playlist.name}</p>
        <p className={metaDataContainerStyle}>
          <small className={metaDataStyle}>{playlist.trackCount} tracks</small>
        </p>

        <p className={metaDataContainerStyle}>
          <small
            className={`${metaDataStyle} ${metaDataClickableStyle}`}
            onClick={() => setShowTracks((v) => !v)}
          >
            {showTracks ? "Hide" : "Show"} Earworms in this Playlist
          </small>
        </p>

        {showTracks ? (
          <div className={playlistEarwormItemContainerStyle}>
            {playlist.earworms.map((earworm) => (
              <PlaylistEarwormItem earworm={earworm} key={earworm.id} />
            ))}
          </div>
        ) : null}

        {!!playlist.spotifyId ? (
          <ExternalLinkButton
            buttonStyle="link"
            href={`https://open.spotify.com/playlist/${playlist.spotifyId}`}
            target="_blank"
          >
            Play in Spotify
          </ExternalLinkButton>
        ) : (
          <Button
            buttonStyle="link"
            isLoading={loading}
            onClick={handleNoSpotifyIdClick}
          >
            Create Spotify Playlist
          </Button>
        )}
      </div>

      {showConnectToSpotifyModal ? (
        <Modal onClose={() => setShowConnectToSpotifyModal(false)} />
      ) : null}
    </>
  )
}
