import React from "react"

import Comment from "./Comment"
import { largeEarwormFeedItemCommentsContainer } from "./LargeEarwormFeedItemComments.css"

// Types
import { EarwormComment } from "./types/earworm"

type LargeEarwormFeedItemFooterCommentsProps = {
  comments: Array<EarwormComment>
}

export default function LargeEarwormFeedItemFooterComments(
  props: LargeEarwormFeedItemFooterCommentsProps
) {
  return (
    <div className={largeEarwormFeedItemCommentsContainer}>
      {props?.comments?.map((comment) => (
        <Comment comment={comment} key={comment.id} />
      ))}
    </div>
  )
}
