import "src/contexts/spotify-player-context.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/contexts/spotify-player-context.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE82UwW7bMAyG730KAUWBDqgMOWmazH2UYQdZohOutmTISuNk6LuPou3UCdphvc0XQyRFfvwpKXs5lUt8VeL3jRCt7zCid4WosAf7TKbSx+ibQsinAE0y1FDFQhhdm/uVuhNS5Io835LrgDbuCrFQY+hJorPQF2KjVFrHoN1UQNe1UNmqE2ZfopElnBDCvcry9eqBHJsN/5aLB5Fni/WK8r/dZCNrBi5CAPsgLi3ottzGxJxnKyaZ7+wxgr2IGjs7B+Xstdi1tT6SEjX0CT79pcUAZmjA+HrfuOTRNW6dpLxNR2YmGZQLFoIM2uKeHCqbFGy1tYSaTIkvdTt6Sm1etsHvnS3EbV7m1WIxA1v8G1jwh2T+te8iVkdpPAE5GlnXagMkdDwA/I17nGKu1F1aNjps0clJLZU9Xsq1/C+oZkCPXwL6sNw51+rjXJ8wjlABt7s4Tff9JBB824vO12iHm9DqQFsvT8QQ3stup60/FMJ5B0xPeskOT0BZpouIDuQOhmJ01JPtFUJEupuSCQsRfcvi7YhVstQp5SFoNkfo4xT53gVbLRgf9KDUxDA/nVcN0GXw1OBtxR9b9qFLptbjtaY/SE9d1mB/srqesDAeWbBZ2BM7pyflsWXhp3an9XXd8+4172bKyocmjTvqCPcy3ygL2/lzsuHQNFqS8flrV//iVJ4zfueMn7LpoSBPNB5rGglGmoKZN2RyszRPs03lV18lzl/pBmsKb7zzPP4r0WYFDBcYj/DwyOdmNwuwF10ZQ7xvfwCMw3mRPgYAAA==\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var artistNameStyle = 'kzb3iva';
export var barBottomStyle = 'kzb3iv3';
export var barStyle = 'kzb3iv1';
export var barTopStyle = 'kzb3iv2';
export var buttonIconRotatedStyle = 'kzb3iv7';
export var buttonIconStyle = 'kzb3iv6';
export var containerStyle = 'kzb3iv0';
export var controlButtonStyle = 'kzb3iv5';
export var controlsStyle = 'kzb3iv4';
export var durationStyle = 'kzb3ivd';
export var metaStyle = 'kzb3iv8';
export var positionStyle = 'kzb3ivc';
export var timeStyle = 'kzb3ivb';
export var trackNameStyle = 'kzb3iv9';