import { Crop } from "react-image-crop"

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 * @param {String} fileType - Type of image file, e.g. image/jpeg or image/png
 */

export default function getCroppedImg(
  image: HTMLImageElement,
  crop: Crop,
  fileName: string,
  fileType: string
): Promise<Blob | null> {
  const canvas = document.createElement("canvas")
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height

  if (crop.width) {
    canvas.width = crop.width * scaleX
  }

  if (crop.height) {
    canvas.height = crop.height * scaleY
  }

  if (!crop.x) {
    crop.x = 0
  }
  if (!crop.y) {
    crop.y = 0
  }
  if (!crop.width) {
    crop.width = image.naturalWidth
  }
  if (!crop.height) {
    crop.height = image.naturalHeight
  }

  const ctx = canvas.getContext("2d")

  if (!ctx) {
    return new Promise((resolve) => resolve(null))
  }

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  )

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        // @ts-ignore
        blob.name = fileName
        resolve(blob)
      },
      fileType,
      1
    )
  })
}
