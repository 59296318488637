import React from "react"
import { useParams } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import { Helmet } from "react-helmet"

import { EARWORM_BASE } from "../../fragments"
import FeedList from "../../FeedList"
import LargeEarwormFeedItem from "../../LargeEarwormFeedItem"

// Types
import { EarwormType } from "../../types/earworm"
import { genreHeaderContainerStyle, genreTitleStyle } from "./GenrePage.css"

const GENRE = gql`
  ${EARWORM_BASE}

  query Genre($genre: String!) {
    genre(genre: $genre) {
      earworms {
        ...EarwormBaseData
      }
      name
    }
  }
`

interface ParamsType {
  genre: string
}

type GenreQueryData = {
  genre: {
    earworms: Array<EarwormType>
    name: string
  }
}

export default function Genre() {
  const { genre } = useParams<ParamsType>()
  const genreName = genre.toLowerCase().replace(/_/g, " ")

  const { data } = useQuery<GenreQueryData>(GENRE, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { genre: genreName },
  })

  return (
    <>
      <Helmet>
        <title>{`Latest ${genreName} music · Earworms`}</title>
        <meta
          property="og:description"
          content={`Discover the latest ${genreName} music shared on Earworms`}
        />
        <meta property="og:image" content="/earworms_square.png" />
        <meta
          name="twitter:title"
          content={`Discover the latest ${genreName} music shared on Earworms`}
        />
        <meta
          name="twitter:description"
          content={`Discover the latest ${genreName} music shared on Earworms`}
        />
      </Helmet>

      <FeedList>
        <div className={genreHeaderContainerStyle}>
          <h2 className={genreTitleStyle}>Latest {genreName} music</h2>
          <p>
            Discover the latest <strong>{genreName}</strong> music shared on
            Earworms
          </p>
        </div>

        {data?.genre?.earworms.map((earworm) => (
          <LargeEarwormFeedItem key={earworm.id} earworm={earworm} />
        ))}
      </FeedList>
    </>
  )
}
