import React, { useState } from "react"
import Modal from "react-modal"
import ReactCrop, { Crop } from "react-image-crop"

import getCroppedImage from "./utils/getCroppedImage"
import Button from "../../Button"
import {
  buttonsContainerStyle,
  containerStyle,
  titleStyle,
} from "./ProfileImageCropModal.css"

Modal.setAppElement("#root")

type ProfileImageCropModalProps = {
  fileType?: string
  image?: string | null
  isOpen: boolean
  /** Called if the user exits the modal without saving **/
  onCancel: () => void
  /** Called when the user has finished cropping **/
  onSuccess: (croppedImgFile: File | null, croppedImg: string | null) => void
}

export default function ProfileImageCropModal(
  props: ProfileImageCropModalProps
) {
  const { fileType, image, isOpen, onCancel, onSuccess } = props

  const [imageRef, setImagRef] = useState<HTMLImageElement | null>(null)
  const [crop, setCrop] = useState<Crop>({
    aspect: 1,
    unit: "%",
    width: 80,
    x: 10,
    y: 10,
  })

  const handleFinished = async () => {
    if (!imageRef) {
      return
    }

    const blob = await getCroppedImage(
      imageRef,
      crop,
      "profileImage",
      fileType || "image/jpeg"
    )

    if (!blob) {
      onSuccess(null, null)
      return
    }

    // Turn the blob back in to a file
    const newFile = new File([blob as BlobPart], "profileImage", {
      type: fileType || "image/jpeg",
    })

    const fileReader = new FileReader()
    fileReader.onload = (e) => {
      if (e?.target?.result) {
        // @ts-ignore
        onSuccess(newFile, e.target.result)
      }
    }
    fileReader.readAsDataURL(blob)
  }

  return (
    <Modal
      htmlOpenClassName="ReactModal__Html--open"
      onRequestClose={onCancel}
      isOpen={isOpen}
    >
      <div className={containerStyle}>
        <h3 className={titleStyle}>Adjust your profile image</h3>
        {image ? (
          <>
            <ReactCrop
              circularCrop
              crop={crop}
              imageStyle={{ maxHeight: 350 }}
              keepSelection
              onChange={(newCrop) => setCrop(newCrop)}
              onImageLoaded={(image) => setImagRef(image)}
              src={image}
              style={{ maxHeight: 350 }}
            />

            <div className={buttonsContainerStyle}>
              <Button buttonStyle="link" onClick={handleFinished}>
                OK
              </Button>
              <Button buttonStyle="text" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </>
        ) : null}
      </div>{" "}
    </Modal>
  )
}
