import React from "react"
import {
  feedListContainerCentreStyle,
  feedListContainerStyle,
} from "./FeedList.css"

export default function FeedList({
  centre,
  children,
}: {
  centre?: boolean
  children: React.ReactNode
}) {
  return (
    <section
      className={centre ? feedListContainerCentreStyle : feedListContainerStyle}
    >
      {children}
    </section>
  )
}
