import React, { useRef } from "react"
import { gql, useMutation } from "@apollo/client"
import Plausible from "plausible-tracker"

import Button from "./Button"
import ContentEditable from "./ContentEditable"

// Icons
import { ReactComponent as Send } from "./icons/send.svg"

// Styles
import {
  formStyle,
  sendIconStyle,
  sendIconHiddenStyle,
  submitButtonStyle,
} from "./AddComment.css"
import { fieldStyles } from "./Field.css"

const { trackEvent } = Plausible()

const ADD_COMMENT = gql`
  mutation addEarwormComment($earwormId: String!, $text: String!) {
    addEarwormComment(earwormId: $earwormId, text: $text) {
      earworm {
        comments {
          createdAt
          id
          profile {
            handle
            id
            name
            profileImage {
              filename
              id
            }
          }
          text
        }
        id
      }
    }
  }
`

type AddCommentProps = {
  earwormId: string
}

export default function AddComment(props: AddCommentProps) {
  const { earwormId } = props

  const commentEl = useRef<HTMLDivElement>(null)

  const [addComment, { loading }] = useMutation(ADD_COMMENT, {
    onCompleted() {
      trackEvent("Added a Comment")

      if (!commentEl?.current?.innerHTML) {
        return
      }

      // Remove the comment from inside the contentEditable div
      commentEl.current.innerHTML = ""
    },
  })

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (!commentEl?.current) {
      return
    }

    const text = commentEl.current?.textContent?.trim()
    if (!text) {
      return
    }

    addComment({
      variables: {
        earwormId,
        text,
      },
    })
  }

  return (
    <div>
      <form className={formStyle} onSubmit={handleSubmit}>
        <div className={fieldStyles}>
          <ContentEditable contentEditable={!loading} ref={commentEl} />
        </div>

        <Button
          buttonStyle="link"
          className={submitButtonStyle}
          disabled={loading}
          isLoading={loading}
          type="submit"
        >
          <Send className={!loading ? sendIconStyle : sendIconHiddenStyle} />
        </Button>
      </form>
    </div>
  )
}
