import React from "react"
import { Link, NavLink } from "react-router-dom"
import { linkButtonStyle } from "./LinkButton.css"

type LinkButtonProps = {
  buttonStyle?: "link" | "primary" | "text"
  children?: React.ReactNode
  className?: string
  onClick?: () => void
  to: string
}

export default function LinkButton(props: LinkButtonProps) {
  const { buttonStyle, children, className, onClick, to } = props

  return (
    <Link
      className={`${linkButtonStyle} ${className} ${buttonStyle}`}
      onClick={onClick}
      to={to}
    >
      {children}
    </Link>
  )
}

type NavLinkButtonProps = LinkButtonProps & { exact?: boolean }

export function NavLinkButton(props: NavLinkButtonProps) {
  const { buttonStyle, children, className, exact = false, to } = props

  return (
    <NavLink
      activeClassName="navItemActive"
      className={`${linkButtonStyle} ${className} ${buttonStyle}`}
      exact={exact}
      to={to}
    >
      {children}
    </NavLink>
  )
}
