import React, { useContext, useEffect, useMemo, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { Transition } from "react-transition-group"
import { Helmet } from "react-helmet"

import supportsSpotifyPlayback from "./memos/supportsSpotifyPlayback"
import TrackQueueModal from "../TrackQueueModal"
import UnconnectedListenToTrackModal from "../UnconnectedListenToTrackModal"

// Icons
import { ReactComponent as Pause } from "../icons/pause.svg"
import { ReactComponent as Play } from "../icons/play.svg"
import { ReactComponent as Skip } from "../icons/skip.svg"

// Styles
import {
  artistNameStyle,
  barBottomStyle,
  barStyle,
  barTopStyle,
  buttonIconRotatedStyle,
  buttonIconStyle,
  containerStyle,
  controlButtonStyle,
  controlsStyle,
  durationStyle,
  metaStyle,
  positionStyle,
  timeStyle,
  trackNameStyle,
} from "./spotify-player-context.css"

// Types
import { EarwormType } from "../types/earworm"
import {
  SpotifyPlayerType,
  SpotifyWebPlaybackState,
  WebPlaybackTrack,
} from "../types/spotify-web-player"

type SpotifyPlayerContextType = {
  setEarworms: (earworms: Array<EarwormType>) => void
  spotifyIsSupported: boolean
}

const SpotifyPlayerContext = React.createContext<SpotifyPlayerContextType>({
  setEarworms: () => {},
  spotifyIsSupported: false,
})

const SPOTIFY_TOKEN = gql`
  query SpotifyToken {
    spotify {
      accessToken
    }
  }
`

const millisToMinutesAndSeconds = (ms: number) => {
  // Round miliseconds to nearest second
  const date = new Date(ms)

  return `${date.getUTCMinutes()}:${date
    .getUTCSeconds()
    .toString()
    .padStart(2, "0")}`
}

type SpotifyTokenData = {
  spotify: {
    accessToken: string
  }
}

type Props = {
  children: React.ReactNode
}

export default function SpotifyPlayerContextComponent(props: Props) {
  const { children } = props

  // Set this to true when the Spotify SDK JS needs to be loaded
  const [loadSpotifySDK, setLoadSpotifySDK] = useState(false)
  // Set this to true when the Spotify SDK has loaded and
  // called the onSpotifyWebPlaybackSDKReady callback
  const [spotifySDKReady, setSpotifySDKReady] = useState(false)
  // Represents whether a track is paused or playing
  const [isPaused, setIsPaused] = useState<null | boolean>(null)
  // Holds the previous tracks in the Spotify queue
  const [previousTracks, setPreviousTracks] = useState<Array<WebPlaybackTrack>>(
    []
  )
  // Holds the next tracks in the Spotify queue
  const [nextTracks, setNextTracks] = useState<Array<WebPlaybackTrack>>([])
  // The current Spotify Player object
  const [player, setPlayer] = useState<SpotifyPlayerType | null>(null)
  // Set this to true when the Spotify Player has connected
  const [isPlayerReady, setIsPlayerReady] = useState(false)
  // The current state of the Spotify Player
  const [playerState, setPlayerState] = useState({
    artist: "",
    duration: 0,
    isPaused: true,
    name: "",
    position: 0,
  })
  // Either null, or, the interval ID of the setInterval which updates
  // the playerState state
  const [playerStateIntervalID, setPlayerStateIntervalID] = useState<
    null | number
  >(null)
  // The Earworms to play
  const [earworms, setEarworms] = useState<Array<EarwormType>>([])
  const [showConnectToSpotifyModal, setShowConnectToSpotifyModal] = useState(
    false
  )

  const spotifyIsSupported = useMemo(() => supportsSpotifyPlayback(), [])

  const { data } = useQuery<SpotifyTokenData>(SPOTIFY_TOKEN, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  })

  useEffect(() => {
    if (!player) {
      if (playerStateIntervalID !== null) {
        setPlayerStateIntervalID(null)
      }
      return
    }

    // If we're playing the track, but don't have a "playerStateIntervalID",
    // then that means we just started playing and haven't set up the interval
    // which polls the player to update the position of the track yet.
    // So we need to do that.
    if (!isPaused && playerStateIntervalID === null) {
      const intervalID = setInterval(async () => {
        if (!player) {
          return
        }

        const newPlayerState = await player?.getCurrentState()
        if (!newPlayerState) {
          return
        }

        setPlayerState({
          artist: newPlayerState.track_window.current_track.artists[0].name,
          duration: newPlayerState.duration,
          isPaused: newPlayerState.paused,
          name: newPlayerState.track_window.current_track.name,
          position: newPlayerState.position,
        })
      }, 800)

      setPlayerStateIntervalID((intervalID as unknown) as number)
    }

    if (isPaused === true && playerStateIntervalID !== null) {
      clearInterval(playerStateIntervalID)
      setPlayerStateIntervalID(null)
    }
  }, [isPaused, player, playerStateIntervalID])

  const createSpotifyPlayer = () => {
    if (!data?.spotify?.accessToken) {
      return
    }

    // Player
    // TODO: Type global Spotify object
    // @ts-ignore
    const player: SpotifyPlayerType = new Spotify.Player({
      name: "Earworms Web",
      getOAuthToken: (callback: any) => {
        // Call the callback with the logged in persons access token
        callback(data.spotify.accessToken)
      },
      volume: 1,
    })

    player.addListener("ready", () => {
      setIsPlayerReady(true)
    })

    // Playback status updates
    player.addListener(
      "player_state_changed",
      (state: SpotifyWebPlaybackState) => {
        if (!state || !player) {
          return
        }

        setIsPaused(state.paused)
        setPreviousTracks(state?.track_window?.previous_tracks || [])
        setNextTracks(state?.track_window?.next_tracks || [])
      }
    )

    player.connect().then((success: boolean) => {
      if (success) {
        // Player has successfully connected
        setPlayer(player)
      }
    })
  }

  useEffect(() => {
    // @ts-ignore
    window.onSpotifyWebPlaybackSDKReady = () => {
      setSpotifySDKReady(true)

      if (data?.spotify?.accessToken) {
        createSpotifyPlayer()
      } else {
        setShowConnectToSpotifyModal(true)
      }
    }
  })

  useEffect(() => {
    if (spotifySDKReady && !data?.spotify?.accessToken) {
      setShowConnectToSpotifyModal(true)
    }

    if (earworms?.length && !player && !spotifySDKReady) {
      setLoadSpotifySDK(true)
      return
    }

    if (!isPlayerReady || !earworms?.length || !player) {
      return
    }

    player._options.getOAuthToken((access_token: string) => {
      fetch(
        `https://api.spotify.com/v1/me/player/play?device_id=${player._options.id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            uris: earworms.map(
              (earworm) => `spotify:track:${earworm.spotifyId}`
            ),
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
    })
  }, [data, earworms, player, isPlayerReady, spotifySDKReady])

  const handlePlayClicked = () => {
    // Toggle play/pause
    if (!player) {
      return
    }

    player.togglePlay()
  }

  const handlePreviousTrackClicked = () => {
    // Go to previous track in the queue
    if (!player) {
      return
    }

    player.previousTrack()
  }

  const handleNextTrackClicked = () => {
    // Go to next track in the queue
    if (!player) {
      return
    }

    player.nextTrack()
  }

  const renderTime = () => {
    return (
      <div className={timeStyle}>
        <span className={positionStyle}>
          {millisToMinutesAndSeconds(playerState?.position)}
        </span>
        <span className={durationStyle}>{`/${millisToMinutesAndSeconds(
          playerState?.duration
        )}`}</span>
      </div>
    )
  }

  return (
    <SpotifyPlayerContext.Provider
      value={{
        setEarworms,
        spotifyIsSupported,
      }}
    >
      <>
        {loadSpotifySDK ? (
          <Helmet>
            <script src="https://sdk.scdn.co/spotify-player.js" />
          </Helmet>
        ) : null}

        {children}

        <Transition in={!!player} timeout={1000}>
          {(state) => (
            <div className={`${containerStyle} ${state}`}>
              <div className={barStyle}>
                <div className={barTopStyle}>
                  <div className={controlsStyle}>
                    <button
                      className={controlButtonStyle}
                      onClick={handlePlayClicked}
                    >
                      {isPaused ? (
                        <Play className={buttonIconStyle} />
                      ) : (
                        <Pause className={buttonIconStyle} />
                      )}
                    </button>

                    <button
                      className={controlButtonStyle}
                      disabled={!previousTracks.length}
                      onClick={handlePreviousTrackClicked}
                    >
                      <Skip
                        className={`${buttonIconStyle} ${buttonIconRotatedStyle}`}
                      />
                    </button>

                    <button
                      className={controlButtonStyle}
                      disabled={!nextTracks.length}
                      onClick={handleNextTrackClicked}
                    >
                      <Skip className={buttonIconStyle} />
                    </button>
                  </div>
                  {playerState?.position ? renderTime() : null}
                </div>

                <div className={barBottomStyle}>
                  <div className={metaStyle}>
                    <span className={trackNameStyle}>
                      {playerState?.name || "‎‏‏‎ ‎"}
                    </span>
                    <span className={artistNameStyle}>
                      {playerState?.artist || "‏‏‎ ‎"}
                    </span>
                  </div>

                  {previousTracks.length > 0 || nextTracks.length > 0 ? (
                    <TrackQueueModal tracks={earworms} />
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </Transition>

        {showConnectToSpotifyModal ? (
          <UnconnectedListenToTrackModal
            onClose={() => setShowConnectToSpotifyModal(false)}
            spotifyId={earworms[0]?.spotifyId}
          />
        ) : null}
      </>
    </SpotifyPlayerContext.Provider>
  )
}

export const useSpotifyPlayer = () => useContext(SpotifyPlayerContext)
