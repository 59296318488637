import React from "react"
import Twemoji from "react-twemoji"
import { containerStyle, textStyle } from "./MemberOfTheMonth.css"

export default function MemberOfTheMonth() {
  return (
    <Twemoji options={{ className: "twemoji", noWrapper: true }}>
      <div className={containerStyle}>
        <span>🏆</span>
        <div className={textStyle}>
          <strong>Community Member of the Month</strong>
          <p>December 2020</p>
        </div>
      </div>
    </Twemoji>
  )
}
