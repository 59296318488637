import React from "react"
import { Link } from "react-router-dom"

import ProfileInfoBox from "./ProfileInfoBox"

// Types
import { Genre } from "../../types/genre"
import { genreTagStyle, tagContainerStyle } from "./ProfileGenreTags.css"

type ProfileGenreTagsProps = {
  genres: Array<Genre>
}

export default function ProfileGenreTags(props: ProfileGenreTagsProps) {
  const { genres } = props

  return (
    <ProfileInfoBox title="Top Genres">
      <div className={tagContainerStyle}>
        {genres.map((genre) => (
          <Link
            className={genreTagStyle}
            key={genre.id}
            to={`/genres/${genre.name.toLowerCase().replace(/ /g, "_")}`}
          >
            {genre.name}
          </Link>
        ))}
      </div>
    </ProfileInfoBox>
  )
}
