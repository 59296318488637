import React from "react"
import { Switch, Route } from "react-router-dom"

import SpotifyPlayerContext from "./contexts/spotify-player-context"
import UserContext from "./contexts/user-context"
import SideBar from "./SideBar"

// Pages
import Genre from "./pages/genres"
import Global from "./pages/global"
import Home from "./pages/home"
import Join from "./pages/join"
import Playlists from "./pages/playlists"
import Profile from "./pages/profile"
import ProfileEarworm from "./pages/profile/earworm"
import Share from "./pages/share"
import Signin from "./pages/signin"
import SpotifyCallback from "./pages/spotify-callback"
import Updates from "./pages/updates"
import Welcome from "./pages/welcome"

import Header from "./Header"

// Styles
import * as appStyles from "./App.css"

export default function App() {
  return (
    <>
      <UserContext>
        <SpotifyPlayerContext>
          <Header />
          <SideBar />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/genres">
              <Genre />
            </Route>
            <Route path="/global">
              <Global />
            </Route>
            <Route path="/join">
              <Join />
            </Route>
            <Route path="/playlists">
              <Playlists />
            </Route>
            <Route path="/share">
              <Share />
            </Route>
            <Route path="/signin">
              <Signin />
            </Route>
            <Route path="/spotify-callback">
              <SpotifyCallback />
            </Route>
            <Route path="/updates">
              <Updates />
            </Route>
            <Route path="/welcome">
              <Welcome />
            </Route>
            <Route
              exact
              path={["/:handle", "/:handle/followers", "/:handle/following"]}
            >
              <Profile />
            </Route>
            <Route path="/:handle/earworm/:earwormId">
              <ProfileEarworm />
            </Route>
          </Switch>
        </SpotifyPlayerContext>
      </UserContext>
      <div className={appStyles.emptyStyles} />
    </>
  )
}
