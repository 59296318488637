import React from "react"

// Styles
import {
  baseStyle,
  isHiddenStyle,
  linkStyle,
  loadingStyle,
  textStyle,
} from "./Button.css"

export type ButtonProps = {
  buttonStyle?: "link" | "text"
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  isLoading?: boolean
  isVisible?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  type?: "button" | "submit"
}

export default function Button(props: ButtonProps) {
  const {
    buttonStyle,
    children,
    className,
    disabled,
    isLoading,
    isVisible = true,
    onClick,
    type,
  } = props

  return (
    <button
      className={`${className} ${baseStyle} ${!isVisible && isHiddenStyle} ${
        buttonStyle === "link" ? linkStyle : ""
      } ${buttonStyle === "text" && textStyle} ${isLoading && loadingStyle} `}
      disabled={disabled === true}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  )
}
