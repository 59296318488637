import React from "react"
import Plausible from "plausible-tracker"

import { useSpotifyPlayer } from "../../contexts/spotify-player-context"
import Button from "../../Button"

// Icons
import { ReactComponent as Play } from "../../icons/play.svg"

// Styles
import {
  containerStyle,
  playButtonIconStyle,
  playFeedButtonStyle,
} from "./PlayMyFeed.css"

// Types
import { EarwormType } from "../../types/earworm"

const { trackEvent } = Plausible()

type PlayMyFeedProps = {
  earworms: Array<EarwormType>
}

export default function PlayMyFeed(props: PlayMyFeedProps) {
  const { earworms } = props

  const { setEarworms, spotifyIsSupported } = useSpotifyPlayer()

  const handlePlayFeedClick = () => {
    if (!earworms) {
      return
    }

    // Track play event
    trackEvent("Played My Feed")

    setEarworms(earworms)
  }

  if (!spotifyIsSupported) {
    return null
  }

  return (
    <div className={containerStyle}>
      <Button
        buttonStyle="link"
        className={playFeedButtonStyle}
        onClick={handlePlayFeedClick}
        type="button"
      >
        <Play className={playButtonIconStyle} /> Play my feed
      </Button>
    </div>
  )
}
