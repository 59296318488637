import React from "react"
import { Link, useParams } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"

import FeedList from "../../FeedList"
import ToggleFollowButton from "./ToggleFollowButton"
import {
  handleLinkStyle,
  handleStyle,
  noAvatarStyle,
  profileImageStyle,
  profileRowStyle,
} from "./ProfileFollowingStyles.css"

// Types
import { Profile } from "../../types/profile"

const FOLLOWERS = gql`
  query Followers($handle: String!) {
    followers(handle: $handle) {
      handle
      id
      isFollowing
      name
      profileImage {
        filename
        id
      }
    }
  }
`

interface ParamsType {
  handle: string
}

type FollowersQueryData = {
  followers: Array<Profile>
}

export default function ProfileFollowersList() {
  const { handle: handleParam } = useParams<ParamsType>()

  const { data } = useQuery<FollowersQueryData>(FOLLOWERS, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { handle: handleParam },
  })

  return (
    <FeedList>
      {data?.followers?.map((d) => (
        <div key={d.handle} className={profileRowStyle}>
          {d?.profileImage ? (
            <img
              alt=""
              className={profileImageStyle}
              src={`/img256/${d.profileImage.filename}`}
            />
          ) : (
            <div className={noAvatarStyle}>
              <span aria-label="" role="img">
                🎵
              </span>
            </div>
          )}

          <Link className={handleLinkStyle} to={`/${d.handle}`}>
            <strong className={handleStyle}>@{d.handle}</strong>
          </Link>
          <ToggleFollowButton profile={d} />
        </div>
      ))}
    </FeedList>
  )
}
