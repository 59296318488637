import React from "react"
import { GenreTagLink as GenreTag } from "./GenreTag"
import { genreTagsStyle } from "./LargeEarwormFeedItemGenres.css"

// Types
import { Genre } from "./types/genre"

type LargeEarwormFeedItemGenresProps = {
  genres: Array<Genre>
}

export default function LargeEarwormFeedItemGenres(
  props: LargeEarwormFeedItemGenresProps
) {
  const { genres } = props

  if (!genres) {
    return null
  }

  return (
    <div className={genreTagsStyle}>
      {genres.map((g) => (
        <GenreTag
          key={g.name}
          to={`/genres/${g.name.toLowerCase().replace(/ /g, "_")}`}
        >
          {g.name}
        </GenreTag>
      ))}
    </div>
  )
}
