import React from "react"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import Twemoji from "react-twemoji"

import FeedList from "../../FeedList"
import {
  genreTagsStyle,
  h2Style,
  h3Style,
  sectionStyle,
  twemojiStyle,
  updateGenreTagStyle,
} from "./index.css"

export default function Updates() {
  return (
    <>
      <Helmet>
        <title>Updates · Earworms</title>
        <meta property="og:description" content={`Updates from Earworms`} />
        <meta property="og:image" content="/earworms_square.png" />
      </Helmet>
      <FeedList>
        <Twemoji
          className={twemojiStyle}
          options={{ className: "twemoji", noWrapper: true }}
        >
          <section itemScope itemType="http://schema.org/Article">
            <h2 className={h2Style} itemProp="headline">
              Earworms January 2021 Community Update
            </h2>
            <meta itemProp="datePublished" content="2021-01-09" />
            <div
              itemScope
              itemProp="publisher"
              itemType="https://schema.org/Organization"
            >
              <meta itemProp="name" content="Earworms" />
              <div
                itemProp="logo"
                itemScope
                itemType="https://schema.org/ImageObject"
              >
                <meta
                  itemProp="url"
                  content="https://earworms.club/apple-touch-icon.png"
                />
              </div>
            </div>
            <section className={sectionStyle}>
              <strong>Happy New Year!</strong>
              <p>
                Hurray! We made it to 2021! Things can only get better from
                here, right? Well, not according to Nostradamus. He apparently
                predicted a zombie apocalypse and a meteor strike,{" "}
                <a
                  href="https://www.theweek.co.uk/ufos/951568/nostradamus-forecast-a-terrible-2021"
                  target="_blank"
                  rel="noreferrer"
                >
                  amongst other events
                </a>{" "}
                in 2021.
              </p>
              <p>In the meantime, let's crack on with the community update.</p>
              <img
                alt="2020 meme"
                itemProp="image"
                src="https://newsletter.earworms.club/2020-01/20202021.jpeg"
              />
            </section>

            <section className={sectionStyle}>
              <h3 className={h3Style}>New Features</h3>
              <strong>Stream Earworms</strong>
              <p>
                Did you know you can now stream tracks directly from Earworms?
              </p>

              <p>
                When you're signed in to the site, just tap "Play track"
                underneath any Earworm to start playing it.
              </p>

              <p>
                At the moment, this works with Chrome, Firefox, Edge and
                Internet Explorer on Mac, Windows and Linux. Hopefully Spotify
                will add support for more browsers and devices soon.
              </p>
            </section>

            <section className={sectionStyle}>
              <strong>Stream Your Feed</strong>
              <p>
                If listening to Earworms one at a time isn't enough you can hit
                the new "Play My Feed" button at the top of your feed to listen
                to tracks shared by the people you follow.
              </p>
              <p>This works with the same web browsers mentioned above.</p>
            </section>

            <section className={sectionStyle}>
              <strong>Earworm Reminder Service</strong>
              <p>Did you ever forget to share an Earworm?</p>
              <p>
                Next time you share your favourite track, we can send you a one
                off reminder that it's been seven days since you last shared.
              </p>
            </section>

            <section className={sectionStyle}>
              <h3 className={h3Style}>Community Member of the Month</h3>
              <img
                alt="Patrick Bateman listening to music"
                src="https://newsletter.earworms.club/2020-01/bateman.gif"
                style={{ marginBottom: "1rem" }}
              />
              <strong>@loz</strong>
              <p>
                🏆 Congratulations to <Link to="/loz">@loz</Link> for being
                awarded Community Member of the Month!
              </p>
              <p>
                His detailed reasons for choosing his Earworms have been likened
                by one community member to the monologues of Patrick Bateman.
              </p>
              <p>
                I'm not sure if <Link to="/loz">@loz</Link> has the same
                motivations, but you can decide for yourself and give him a
                follow on his profile.
              </p>
            </section>

            <section className={sectionStyle}>
              <h3 className={h3Style}>Most Shared Genres Last Month</h3>
              <p>Here's the most shared genres on Earworms last month.</p>
              <p>
                If you want to see more of your favourite genre, then you'll
                have to invite your friends...
              </p>
              <p>
                Unless your friends don't share your taste in music, then you'll
                have to find new friends. And invite them.
              </p>
              <div className={genreTagsStyle}>
                <Link
                  className={updateGenreTagStyle}
                  to="/genres/alternative_rock"
                >
                  alternative rock
                </Link>
                <Link className={updateGenreTagStyle} to="/genres/indie_rock">
                  indie rock
                </Link>
                <Link
                  className={updateGenreTagStyle}
                  to="/genres/alternative_metal"
                >
                  alternative metal
                </Link>
                <Link className={updateGenreTagStyle} to="/genres/indie_pop">
                  indie pop
                </Link>
                <Link className={updateGenreTagStyle} to="/genres/pop_music">
                  pop music
                </Link>
                <Link className={updateGenreTagStyle} to="/genres/disco">
                  disco
                </Link>
              </div>
            </section>

            <section className={sectionStyle}>
              <p>Speak soon and stay safe,</p>
              <span itemProp="author">
                <Link to="/steven">Steven</Link>
              </span>
            </section>
          </section>
        </Twemoji>
      </FeedList>
    </>
  )
}
