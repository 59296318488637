import React, { useState } from "react"
import { gql, useQuery } from "@apollo/client"
import Twemoji from "react-twemoji"

import { EARWORM_BASE } from "../../fragments"
import { useUser } from "../../contexts/user-context"
import Button from "../../Button"
import FeedList from "../../FeedList"
import LargeEarwormFeedItem from "../../LargeEarwormFeedItem"
import WelcomeCard from "../../WelcomeCard"

// Types
import { EarwormType } from "../../types/earworm"
import { globalFeedTitleStyle, largeStyledTwemojiStyle } from "./GlobalFeed.css"
import { feedTitleStyle } from "../../FeedTitle.css"

const LATEST_EARWORMS = gql`
  ${EARWORM_BASE}

  query LatestEarworms($offset: Int, $limit: Int) {
    latestEarworms(offset: $offset, limit: $limit) {
      ...EarwormBaseData
    }
  }
`

type LatestEarwormsData = {
  latestEarworms: Array<EarwormType>
}

const NUMBER_OF_EARWORMS_PER_PAGE = 10

export default function Home() {
  const { isAuthenticatingUser } = useUser()

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false)

  const { data, fetchMore, loading } = useQuery<LatestEarwormsData>(
    LATEST_EARWORMS,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: {
        limit: NUMBER_OF_EARWORMS_PER_PAGE,
        offset: 0,
      },
      onCompleted(data) {
        // Called when the query completes (first time, not after fetchMore)
        if (data?.latestEarworms?.length === NUMBER_OF_EARWORMS_PER_PAGE) {
          setShowLoadMoreButton(true)
        }
      },
    }
  )

  const fetchMoreLatestEarworms = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // Remove the focus from the load more button to prevent scroll jumping
    event.currentTarget.blur()

    fetchMore({
      variables: {
        offset: data?.latestEarworms?.length || 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          setShowLoadMoreButton(false)
          return prev
        }

        if (
          fetchMoreResult.latestEarworms.length < NUMBER_OF_EARWORMS_PER_PAGE
        ) {
          setShowLoadMoreButton(false)
        } else {
          setShowLoadMoreButton(true)
        }

        return Object.assign({}, prev, {
          latestEarworms: [
            ...prev.latestEarworms,
            ...fetchMoreResult.latestEarworms,
          ],
        })
      },
    })
  }

  if (isAuthenticatingUser) {
    return null
  }

  return (
    <FeedList centre>
      <WelcomeCard />

      <h4 className={`${feedTitleStyle} ${globalFeedTitleStyle}`}>
        <Twemoji
          className={largeStyledTwemojiStyle}
          options={{ className: "twemoji", noWrapper: true }}
        >
          <span role="img" aria-label="🌍">
            🌍
          </span>
        </Twemoji>{" "}
        <span>Global Earworms Feed</span>
      </h4>

      {data?.latestEarworms.map((earworm: EarwormType) => (
        <LargeEarwormFeedItem key={earworm.id} earworm={earworm} />
      ))}

      {showLoadMoreButton && (
        <Button
          buttonStyle="link"
          isLoading={loading}
          onClick={fetchMoreLatestEarworms}
        >
          Load More Earworms
        </Button>
      )}
    </FeedList>
  )
}
