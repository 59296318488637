import React from "react"
import { gql, useMutation } from "@apollo/client"

import { useUser } from "../../contexts/user-context"
import Button from "../../Button"

// Icons
import { ReactComponent as UserFollow } from "../../icons/userFollow.svg"
import { ReactComponent as UserUnfollow } from "../../icons/userUnfollow.svg"

// Types
import { Profile } from "../../types/profile"
import { iconStyle } from "./ToggleFollowIcon.css"

const TOGGLE_FOLLOW = gql`
  mutation ToggleFollow($handle: String!) {
    toggleFollow(handle: $handle) {
      profiles {
        followerCount
        followingCount
        handle
        id
        isFollowing
      }
    }
  }
`

type ToggleFollowButtonProps = {
  profile: Profile
}

export default function ToggleFollowButton(props: ToggleFollowButtonProps) {
  const { user } = useUser()

  const { profile } = props
  const { handle, id, isFollowing } = profile

  const [toggleFollow, { loading }] = useMutation(TOGGLE_FOLLOW)

  if (user === null) {
    return null
  }

  if (user.profile.id === id) {
    return null
  }

  const handleFollowClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // Remove the focus from the button
    event.currentTarget.blur()

    toggleFollow({
      variables: {
        handle,
      },
    })
  }

  return (
    <div>
      {isFollowing ? (
        <Button
          buttonStyle="text"
          isLoading={loading}
          onClick={handleFollowClick}
        >
          <UserUnfollow className={iconStyle} />
          Unfollow
        </Button>
      ) : (
        <Button
          buttonStyle="link"
          isLoading={loading}
          onClick={handleFollowClick}
        >
          <UserFollow className={iconStyle} />
          Follow
        </Button>
      )}
    </div>
  )
}
