import React from "react"
import { contentEditableStyle } from "./ContentEditable.css"

type ContentEditableProps = {
  contentEditable: boolean
}

const ContentEditable = React.forwardRef<HTMLDivElement, ContentEditableProps>(
  (props, ref) => {
    const { contentEditable } = props

    return (
      <div
        className={contentEditableStyle}
        contentEditable={contentEditable}
        ref={ref}
      />
    )
  }
)

export default ContentEditable
