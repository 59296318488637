import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { Helmet } from "react-helmet"
import Twemoji from "react-twemoji"
import Plausible from "plausible-tracker"

import { EARWORM_BASE } from "../../fragments"
import { useUser } from "../../contexts/user-context"
import Button from "../../Button"
import ChooseGenres from "./ChooseGenres"
import FeedList from "../../FeedList"
import HelpText from "../../HelpText"
import ImageFadeLoad from "../../ImageFadeLoad"
import Input from "../../Input"
import Textarea from "../../Textarea"
import TimeUntilNextEarworm from "./TimeUntilNextEarworm"
import {
  buttonsStyle,
  checkboxContainerStyle,
  checkboxLabelStyle,
  checkboxStyle,
  coverArtStyle,
  messageBodyStyle,
  messageStyle,
  titleStyle,
  trackDetailsStyle,
  trackNameStyle,
} from "./index.css"

// Types
import { EarwormType } from "../../types/earworm"
import { fieldStyles } from "../../Field.css"
import { formErrorTextStyle } from "../../FormErrorText.css"

const { trackEvent } = Plausible()

const CREATE_EARWORM = gql`
  ${EARWORM_BASE}

  mutation createEarworm($earworm: CreateEarwormInput!) {
    createEarworm(input: $earworm) {
      errorMessage
      isError
      earworm {
        ...EarwormBaseData
      }
    }
  }
`

const TRACK_INFO = gql`
  query TrackInfo($query: String) {
    trackInfo(query: $query) {
      artist
      imageUrl
      isrc
      name
      spotifyId
      spotifyUri
    }
  }
`

export default function Share() {
  const history = useHistory()
  const { isAuthenticatingUser, user } = useUser()

  const [message, setMessage] = useState("")
  const [selectedTrack, setSelectedTrack] = useState<{
    artist: string
    imageUrl?: string
    isrc?: string
    name: string
    spotifyId?: string
    spotifyUri?: string
  } | null>(null)
  const [trackUri, setTrackUri] = useState<string>("")
  const [remindAboutEarworm, setRemindAboutEarworm] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [earwormShared, setEarwormShared] = useState<EarwormType | null>(null)

  const [createEarworm, { loading }] = useMutation(CREATE_EARWORM, {
    onCompleted(data) {
      if (data?.createEarworm?.isError) {
        setErrorMessage(data?.createEarworm?.errorMessage)
        return
      }

      trackEvent("Shared an Earworm")

      if (!user) {
        history.push("/")
        return
      }

      // Something has gone wrong
      if (!data?.createEarworm?.earworm) {
        history.push("/")
        return
      }

      setEarwormShared(data.createEarworm.earworm)
    },
  })

  const [getTrackInfo, { loading: isSearching }] = useLazyQuery(TRACK_INFO, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      setSelectedTrack(data?.trackInfo)
    },
  })

  // If the user is not signed in, then redirect to signin page
  useEffect(() => {
    if (!user && !isAuthenticatingUser) {
      history.push("/signin", { redirectTo: "/share" })
    }
  }, [history, isAuthenticatingUser, user])

  // Reset state if user cancels selected track
  const handleCancel = () => {
    setMessage("")
    setSelectedTrack(null)
    setTrackUri("")
  }

  const handleSubmit = () => {
    if (!selectedTrack) {
      return
    }

    createEarworm({
      variables: {
        earworm: {
          artist: selectedTrack.artist,
          imageUrl: selectedTrack?.imageUrl,
          isrc: selectedTrack?.isrc,
          message,
          name: selectedTrack.name,
          remindInAWeek: remindAboutEarworm,
          spotifyId: selectedTrack?.spotifyId,
          spotifyUri: selectedTrack?.spotifyUri,
        },
      },
    })
  }

  const renderForm = () => {
    const handleTrackUriChange = (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value
      setTrackUri(value)

      if (value.includes("spotify.") && value.includes("/track/")) {
        getTrackInfo({
          variables: {
            query: value,
          },
        })
      }
    }

    return (
      <>
        <Helmet>
          <title>{`Share Earworm · Earworms`}</title>
        </Helmet>

        {!selectedTrack && (
          <>
            <Input
              autoComplete="off"
              autoFocus
              disabled={isSearching}
              name="searchArtist"
              onChange={handleTrackUriChange}
              placeholder="Paste Spotify Song Link here"
              type="text"
              value={trackUri}
            />

            <HelpText>Paste a Spotify Song Link in here</HelpText>

            <div className={messageStyle}>
              <div className={messageBodyStyle}>
                <strong>Don't have a Spotify account?</strong>
                <p>
                  No problem, you can still find your track, for free, at the{" "}
                  <a
                    href="https://open.spotify.com/search"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Spotify Web Player
                  </a>
                </p>
              </div>
            </div>
          </>
        )}

        {selectedTrack && !earwormShared ? (
          <>
            <div className={trackDetailsStyle}>
              {selectedTrack?.imageUrl && (
                <ImageFadeLoad
                  alt={`Cover art for ${selectedTrack?.name} by ${selectedTrack?.artist}`}
                  className={coverArtStyle}
                  src={selectedTrack?.imageUrl}
                />
              )}

              <div>
                <strong className={trackNameStyle}>{selectedTrack.name}</strong>
                <em>{selectedTrack?.artist}</em>
              </div>
            </div>

            <div className={fieldStyles}>
              <Textarea
                placeholder="Add a comment..."
                name="message"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              ></Textarea>
            </div>

            <div className={checkboxContainerStyle}>
              <input
                className={checkboxStyle}
                id="reminder"
                name="reminder"
                onChange={(e) => setRemindAboutEarworm(e.target.checked)}
                type="checkbox"
              />
              <label className={checkboxLabelStyle} htmlFor="reminder">
                Send me a <strong>one off</strong> reminder to share an Earworm
                next week
              </label>
            </div>

            <nav className={buttonsStyle}>
              <Button buttonStyle="text" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                buttonStyle="link"
                disabled={loading || !!errorMessage}
                isLoading={loading}
                onClick={handleSubmit}
                type="submit"
              >
                Share Earworm
              </Button>
            </nav>

            {errorMessage && (
              <p className={formErrorTextStyle}>{errorMessage}</p>
            )}
          </>
        ) : null}

        {earwormShared !== null ? (
          <ChooseGenres earworm={earwormShared} />
        ) : null}
      </>
    )
  }

  if (!user) {
    return null
  }

  // If the user has already shared an Earworm this week,
  // then let them know how long until they can share another
  if (user?.profile.thisWeeksEarworm) {
    return <TimeUntilNextEarworm />
  }

  return (
    <FeedList>
      <h3 className={titleStyle}>
        <Twemoji options={{ className: "twemoji", noWrapper: true }}>
          {earwormShared === null ? "Share Your Earworm" : "Shared!"}{" "}
          <span aria-label="golden heart emoji" role="img">
            💛
          </span>
        </Twemoji>
      </h3>
      {renderForm()}
    </FeedList>
  )
}
