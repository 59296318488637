import React, { useEffect, useRef, useState } from "react"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { Formik } from "formik"
import debounce from "lodash.debounce"
import loadImage from "blueimp-load-image"

import AutoComplete from "../../AutoComplete"
import Button from "../../Button"
import { ReactComponent as Camera } from "../../icons/camera.svg"
import HelpText from "../../HelpText"
import Input from "../../Input"
import ProfileImage from "./ProfileImage"
import ProfileImageCropModal from "./ProfileImageCropModal"
import Textarea from "../../Textarea"

// Styles
import { fieldStyles } from "../../Field.css"
import { labelStyles } from "../../Label.css"
import {
  buttonsContainerStyle,
  editProfileImageStyle,
  fileInputStyle,
  formStyle,
  profileDetailsStyle,
  profileImageContainerStyle,
  suggestedLocationNameStyle,
  suggestionSubTextStyle,
} from "./ProfileHeaderEdit.css"

// Types
import { Profile } from "../../types/profile"

const SEARCH_LOCATION = gql`
  query SearchLocation($query: String) {
    searchLocation(query: $query) {
      country
      id
      point {
        coordinates
      }
      name
      state
    }
  }
`

const UPDATE_PROFILE = gql`
  mutation updateProfile(
    $avatar: Upload
    $updatedProfile: UpdateProfileInput!
  ) {
    updateProfile(avatar: $avatar, input: $updatedProfile) {
      bio
      handle
      id
      isFollowing
      location {
        country
        id
        name
      }
      name
      profileImage {
        filename
        id
      }
    }
  }
`

type Location = {
  country?: string
  id?: string
  point?: {
    coordinates?: Array<number>
  }
  name: string
  state?: string
}

type Values = {
  bio?: string
  location?: string
  name?: string
}

type ProfileHeaderEditProps = {
  onComplete: () => void
  profile: Profile
}

const renderAutoCompleteSuggestion = (location: Location) => {
  let subText = ""
  if (location.state) {
    subText = `${location.state}`
  }
  if (location.state && location.country) {
    subText = `${subText}, `
  }
  if (location.country) {
    subText = `${subText}${location.country}`
  }

  return (
    <>
      <p>{location.name}</p>
      <small className={suggestionSubTextStyle}>{subText}</small>
    </>
  )
}

export default function ProfileHeaderEdit(props: ProfileHeaderEditProps) {
  const { onComplete, profile } = props
  const { handle, profileImage } = profile

  const fileInput = useRef() as React.MutableRefObject<HTMLInputElement>
  const [locationSuggestions, setLocationSuggestions] = useState([])

  const [newAvatar, setNewAvatar] = useState<File | null>(null)
  const [newAvatarPreview, setNewAvatarPreview] = useState<string | null>(null)
  const [croppedNewAvatar, setCroppedNewAvatar] = useState<File | null>(null)

  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  )
  const [showModal, setShowModal] = useState(false)

  const [searchLocation, { loading: isLoadingLocations }] = useLazyQuery(
    SEARCH_LOCATION,
    {
      onCompleted(data) {
        setLocationSuggestions(data?.searchLocation)
      },
    }
  )

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
    onCompleted() {
      // Let parent component know we are finished
      onComplete()
    },
  })

  const debouncedSearchLocation = debounce(searchLocation, 500)

  // If a new avatar has been selected, load the local file,
  // so it can be previewed to the user
  useEffect(() => {
    if (!newAvatar) {
      return
    }

    const reader = new FileReader()
    reader.onload = function (e) {
      if (!reader.result || typeof reader.result !== "string") {
        return
      }

      setNewAvatarPreview(reader.result)
      setShowModal(true)
    }
    reader.readAsDataURL(newAvatar)
  }, [newAvatar])

  const handleCancelImageCrop = () => {
    // Close the modal and get rid of the temporary image
    setShowModal(false)
    setNewAvatar(null)
    setNewAvatarPreview(null)
    fileInput.current.value = ""
  }

  const handleImageCropSuccess = (
    croppedImgFile: File | null,
    croppedImg: string | null
  ) => {
    // Close the modal
    setShowModal(false)

    if (croppedImgFile) {
      setCroppedNewAvatar(croppedImgFile)
    }

    if (croppedImg) {
      setNewAvatarPreview(croppedImg)
    }
  }

  const initialValues = {
    bio: profile?.bio || "",
    location: profile?.location?.name || "",
    name: profile?.name || "",
  }

  const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault()

    const file = e?.currentTarget?.files?.[0]
    if (!file) {
      return
    }

    // Load the image using this library because otherwise
    // images can be oriented the wrong way
    loadImage(
      file,
      (img) => {
        if (img instanceof HTMLCanvasElement) {
          img.toBlob((blob) => {
            if (!blob) {
              return
            }

            // Turn the blob back in to a file
            const newFile = new File([blob as BlobPart], file.name, {
              type: file.type,
            })

            setNewAvatar(newFile)
          }, file.type)
        }
      },
      { canvas: true, orientation: true }
    )
  }

  const handleSubmit = (values: Values) => {
    const { bio, name } = values

    updateProfile({
      variables: {
        avatar: croppedNewAvatar,
        updatedProfile: {
          bio,
          location: {
            coordinates: selectedLocation?.point?.coordinates,
            name: selectedLocation?.name,
            state: selectedLocation?.state,
            country: selectedLocation?.country,
          },
          name,
        },
      },
    })
  }

  const validateForm = (values: Values) => {
    const errors: any = {}

    if (!values.name) {
      errors.name = "Please enter your name"
    }

    if ((values?.bio?.length || 0) > 300) {
      errors.bio = "Sorry, your bio must be less than 300 characters"
    }

    return errors
  }

  const renderForm = ({
    errors,
    handleChange,
    handleSubmit,
    setValues,
    touched,
    values,
  }: any) => {
    const handleLocationSelected = (
      event: any,
      { suggestion }: { suggestion: { name: string } }
    ) => {
      setSelectedLocation(suggestion)
      setValues({ ...values, location: suggestion.name })
    }

    return (
      <form className={formStyle} onSubmit={handleSubmit}>
        <div
          className={profileImageContainerStyle}
          onClick={() => fileInput?.current?.click()}
        >
          {(newAvatarPreview || profileImage) && (
            <ProfileImage
              alt={`Avatar for profile of ${handle}`}
              src={newAvatarPreview || `/img256/${profileImage.filename}`}
            />
          )}
          <div className={editProfileImageStyle}>
            <Camera />
          </div>
        </div>

        <input
          accept="image/png, image/jpeg"
          className={fileInputStyle}
          onChange={handleFileChange}
          ref={fileInput}
          type="file"
        />

        <div className={fieldStyles}>
          <label className={labelStyles}>Name</label>
          <Input name="name" onChange={handleChange} value={values.name} />
          {touched.name && errors.name ? (
            <HelpText isDanger>{errors.name}</HelpText>
          ) : null}
        </div>

        <div className={fieldStyles}>
          <label className={labelStyles}>Bio</label>
          <Textarea name="bio" onChange={handleChange} value={values.bio} />
          {touched.bio && errors.bio ? (
            <HelpText isDanger>{errors.bio}</HelpText>
          ) : null}
        </div>

        <div className={fieldStyles}>
          <label className={labelStyles}>Location</label>
          {selectedLocation !== null ? (
            <span
              className={suggestedLocationNameStyle}
              onClick={() => setSelectedLocation(null)}
            >
              {selectedLocation.name}
            </span>
          ) : (
            <AutoComplete
              isSearching={isLoadingLocations}
              onChange={handleChange}
              onSuggestionSelected={handleLocationSelected}
              onSuggestionsFetchRequested={({ value }) =>
                debouncedSearchLocation({ variables: { query: value } })
              }
              renderSuggestion={renderAutoCompleteSuggestion}
              suggestions={locationSuggestions}
              value={values?.location || ""}
            />
          )}
          {touched.location && errors.location ? (
            <HelpText isDanger>{errors.location}</HelpText>
          ) : null}
        </div>

        <div className={buttonsContainerStyle}>
          <Button
            buttonStyle="link"
            disabled={loading}
            isLoading={loading}
            type="submit"
          >
            Save Changes
          </Button>
          {!loading && (
            <Button onClick={onComplete} buttonStyle="text" type="button">
              Cancel
            </Button>
          )}
        </div>
      </form>
    )
  }

  return (
    <section className={profileDetailsStyle}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validateForm}
        validateOnMount={true}
      >
        {renderForm}
      </Formik>

      <ProfileImageCropModal
        fileType={newAvatar?.type}
        image={newAvatarPreview}
        isOpen={showModal}
        onCancel={handleCancelImageCrop}
        onSuccess={handleImageCropSuccess}
      />
    </section>
  )
}
