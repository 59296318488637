import React, { useEffect, useState } from "react"
import intervalToDuration from "date-fns/intervalToDuration"
import startOfWeek from "date-fns/startOfWeek"

import FeedList from "../../FeedList"
import LabelValueBox from "../../LabelValueBox"
import LinkButton from "../../LinkButton"
import { feedTitleStyle } from "../../FeedTitle.css"
import {
  infoStyle,
  linkStyle,
  timeUntilStyle,
} from "./TimeUntilNextEarworm.css"

export default function TimeUntilNextEarworm() {
  const [intervalToNextWeek, setIntervalToNextWeek] = useState<Duration | null>(
    null
  )

  const nextWeek = new Date(Date.now())
  nextWeek.setDate(nextWeek.getDate() + 7)

  const startOfNextWeek = startOfWeek(nextWeek, { weekStartsOn: 1 })

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIntervalToNextWeek(
        intervalToDuration({
          start: Date.now(),
          end: startOfNextWeek,
        })
      )
    }, 1000)

    return () => clearTimeout(timeoutId)
  }, [startOfNextWeek])

  return (
    <FeedList>
      <h4 className={feedTitleStyle}>
        You've Already Shared An Earworm This Week
      </h4>

      <p className={infoStyle}>You can share one Earworm a week.</p>

      <p className={infoStyle}>
        An Earworms week starts at midnight on Sunday (UTC).
      </p>

      {intervalToNextWeek && (
        <>
          <p className={infoStyle}>You can share again in...</p>

          <div className={timeUntilStyle}>
            <LabelValueBox label="days" value={intervalToNextWeek.days} />
            <LabelValueBox label="hours" value={intervalToNextWeek.hours} />
            <LabelValueBox label="minutes" value={intervalToNextWeek.minutes} />
            <LabelValueBox label="seconds" value={intervalToNextWeek.seconds} />
          </div>
          <LinkButton buttonStyle="link" className={linkStyle} to="/">
            Discover Your Next Earworm
          </LinkButton>
        </>
      )}
    </FeedList>
  )
}
