import React from "react"
import { Link } from "react-router-dom"

import ImageFadeLoad from "../../ImageFadeLoad"

// Styles
import {
  artistStyle,
  containerLinkStyle,
  earwormInfoStyle,
  handleStyle,
  noAvatarStyle,
  profileImageStyle,
  profileInfoStyle,
  trackNameStyle,
} from "./PlaylistEarwormItem.css"

// Types
import { EarwormType } from "../../types/earworm"

type PlaylistEarwormItemProps = {
  earworm: EarwormType
}

export default function PlaylistEarwormItem(props: PlaylistEarwormItemProps) {
  const { earworm } = props
  const { artist, profile, trackName } = earworm
  const { handle, profileImage } = profile

  return (
    <Link
      className={containerLinkStyle}
      to={`/${handle}/earworm/${earworm.id}`}
    >
      <div className={profileInfoStyle}>
        {profile.profileImage ? (
          <ImageFadeLoad
            alt={`Profile image of @{handle}`}
            className={profileImageStyle}
            src={`/img256/${profileImage.filename}`}
          />
        ) : (
          <div className={noAvatarStyle}>
            <span aria-label="" role="img">
              🎵
            </span>
          </div>
        )}
        <div>
          <small className={handleStyle}>@{handle} added</small>
          <div className={earwormInfoStyle}>
            <span className={trackNameStyle}>{trackName}</span>
            <span className={artistStyle}>
              <span>{" - "}</span>
              {artist}
            </span>
          </div>
        </div>
      </div>
    </Link>
  )
}
