import React from "react"
import Twemoji from "react-twemoji"

import { useUser } from "./contexts/user-context"
import { NavLinkButton } from "./LinkButton"
import {
  mainNavLinksLinkButton,
  mainNavLinksTwemojiStyle,
} from "./MainNavLinks.css"

export default function MainNavLinks() {
  const { user } = useUser()

  return (
    <>
      <NavLinkButton className={mainNavLinksLinkButton} exact to="/">
        <Twemoji
          className={mainNavLinksTwemojiStyle}
          options={{ className: "twemoji", noWrapper: true }}
        >
          <span role="img" aria-label="🏠">
            🏠
          </span>{" "}
          Home
        </Twemoji>
      </NavLinkButton>

      <NavLinkButton className={mainNavLinksLinkButton} to="/genres">
        <Twemoji
          className={mainNavLinksTwemojiStyle}
          options={{ className: "twemoji", noWrapper: true }}
        >
          <span role="img" aria-label="👨‍🎤">
            👨‍🎤
          </span>{" "}
          Genres
        </Twemoji>
      </NavLinkButton>

      <NavLinkButton className={mainNavLinksLinkButton} to="/share">
        <Twemoji
          className={mainNavLinksTwemojiStyle}
          options={{ className: "twemoji", noWrapper: true }}
        >
          <span role="img" aria-label="🎵">
            🎵
          </span>{" "}
          Share
        </Twemoji>
      </NavLinkButton>

      {user?.profile?.handle ? (
        <NavLinkButton className={mainNavLinksLinkButton} exact to="/global">
          <Twemoji
            className={mainNavLinksTwemojiStyle}
            options={{ className: "twemoji", noWrapper: true }}
          >
            <span role="img" aria-label="🌍">
              🌍
            </span>{" "}
            Community
          </Twemoji>
        </NavLinkButton>
      ) : null}

      <NavLinkButton className={mainNavLinksLinkButton} to="/updates">
        <Twemoji
          className={mainNavLinksTwemojiStyle}
          options={{ className: "twemoji", noWrapper: true }}
        >
          <span role="img" aria-label="📢">
            📢
          </span>{" "}
          Updates
        </Twemoji>
      </NavLinkButton>

      {user?.profile?.handle ? (
        <NavLinkButton
          className={mainNavLinksLinkButton}
          to={`/${user.profile.handle}`}
        >
          <Twemoji
            className={mainNavLinksTwemojiStyle}
            options={{ className: "twemoji", noWrapper: true }}
          >
            <span role="img" aria-label="👤">
              👤
            </span>{" "}
            Me
          </Twemoji>
        </NavLinkButton>
      ) : null}
    </>
  )
}
