import React from "react"
import { Link } from "react-router-dom"
import formatRelative from "date-fns/formatRelative"
import Twemoji from "react-twemoji"

import ImageFadeLoad from "./ImageFadeLoad"

// Types
import { EarwormComment } from "./types/earworm"

// Styles
import {
  avatarStyle,
  containerStyle,
  emojiStyle,
  handleStyle,
  linkStyle,
  messageStyle,
  nameAndAvatarStyle,
  noAvatarStyle,
  separatorStyle,
} from "./Comment.css"

type CommentProps = {
  comment: EarwormComment
}

export default function Comment(props: CommentProps) {
  const { comment } = props
  const { createdAt, profile, text } = comment

  return (
    <div className={containerStyle}>
      <Link className={linkStyle} to={`/${profile.handle}`}>
        <div className={nameAndAvatarStyle}>
          {profile.profileImage ? (
            <ImageFadeLoad
              alt={`Avatar for profile of ${profile.handle}`}
              className={avatarStyle}
              src={`/img256/${profile.profileImage.filename}`}
            />
          ) : (
            <div className={noAvatarStyle}>
              <span aria-label="" role="img">
                🎵
              </span>
            </div>
          )}
          <small className={handleStyle}>@{profile.handle}</small>
          <span className={separatorStyle}>{" · "}</span>
          <small className={handleStyle}>
            {formatRelative(new Date(parseInt(createdAt, 10)), Date.now())}
          </small>
        </div>
      </Link>

      <Twemoji
        options={{ className: `twemoji ${emojiStyle}`, noWrapper: true }}
      >
        <p className={messageStyle}>{text}</p>
      </Twemoji>
    </div>
  )
}
