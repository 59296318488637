import React, { useState } from "react"
import Twemoji from "react-twemoji"

import FeedList from "./FeedList"
import Button from "./Button"

// Styles
import {
  acceptButtonEmojiStyle,
  acceptButtonStyle,
  containerStyle,
  heartEmojiStyle,
  heroStyle,
  listItemStyle,
  listStyle,
  mainTitleStyle,
  subTitleStyle,
} from "./WelcomeCard.css"

export default function WelcomeCard() {
  const [showWelcomeCard, setShowWelcomeCard] = useState(
    typeof localStorage === "undefined" ||
      localStorage?.getItem("hideWelcomeCard") !== "true"
  )

  const handleThanksClick = () => {
    // Remember to keep WelcomeCard hidden
    localStorage.setItem("hideWelcomeCard", "true")

    // Hide card
    setShowWelcomeCard(false)
  }

  if (!showWelcomeCard) {
    return null
  }

  return (
    <div className={containerStyle}>
      <section className={heroStyle}>
        <FeedList>
          <h1 className={mainTitleStyle}>Welcome to Earworms</h1>
          <h2 className={subTitleStyle}>
            A community of music fans sharing the tunes we can't stop listening
            to
          </h2>
          <ul className={listStyle}>
            <li className={listItemStyle}>
              <h2 className={subTitleStyle}>
                Share your favourite track of the week
              </h2>
            </li>
            <li className={listItemStyle}>
              <h2 className={subTitleStyle}>Follow your friends</h2>
            </li>
            <li className={listItemStyle}>
              <h2 className={subTitleStyle}>Listen to new music</h2>
            </li>
          </ul>

          <Button className={acceptButtonStyle} onClick={handleThanksClick}>
            <Twemoji
              options={{
                className: `twemoji, ${acceptButtonEmojiStyle}`,
                noWrapper: true,
              }}
            >
              <span className={heartEmojiStyle}>
                <span role="img" aria-label="✅">
                  ✅
                </span>
              </span>
            </Twemoji>
            <span>Cool, great to be here!</span>
          </Button>
        </FeedList>
      </section>
    </div>
  )
}
