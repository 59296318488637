import React from "react"
import { imageFadeLoadStyle } from "./ImageFadeLoad.css"

type ImageFadeLoadProps = {
  alt: string
  className?: string
  src: string
}

export default function ImageFadeLoad(props: ImageFadeLoadProps) {
  const { alt, className, src } = props

  return (
    <img alt={alt} className={`${imageFadeLoadStyle} ${className}`} src={src} />
  )
}
