import React from "react"

import { useUser } from "./contexts/user-context"
import BaseModal from "./BaseModal"
import ExternalLinkButton from "./ExternalLinkButton"
import {
  modalDescriptionStyle,
  modalTitleStyle,
  spotifyIconStyle,
} from "./Modal.css"

type ModalProps = {
  onClose: () => void
}

export default function Modal(props: ModalProps) {
  const { onClose } = props

  const { user } = useUser()

  return (
    <BaseModal onClose={onClose}>
      <img
        alt="spotify logo"
        className={spotifyIconStyle}
        src="spotify_icon_green.png"
      />

      <p className={modalTitleStyle}>Hey @{user?.profile?.handle}</p>

      <p className={modalDescriptionStyle}>
        To save playlists you need to connect your Spotify account with Earworms
      </p>
      <ExternalLinkButton
        buttonStyle="link"
        href={`https://accounts.spotify.com/authorize?client_id=56e0759e23dc43aeb3f45b764f0075c7&response_type=code&redirect_uri=${process.env.REACT_APP_SPOTIFY_CALLBACK_URI}&scope=playlist-modify-public%20playlist-modify-private%20user-modify-playback-state%20streaming%20user-read-email%20user-read-private`}
      >
        Connect to Spotify
      </ExternalLinkButton>
    </BaseModal>
  )
}
