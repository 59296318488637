import React from "react"
import { Helmet } from "react-helmet"

import { useUser } from "../../contexts/user-context"

import FeedList from "../../FeedList"
import GlobalFeed from "../global/GlobalFeed"
import MyFeed from "./MyFeed"
import WelcomeCard from "../../WelcomeCard"

export default function Home() {
  const { isAuthenticatingUser, user } = useUser()

  if (isAuthenticatingUser) {
    if (typeof window === "undefined") {
      return (
        <FeedList>
          <WelcomeCard />
        </FeedList>
      )
    }

    return null
  }

  return (
    <>
      <Helmet>
        <title>{`Earworms`}</title>
        <meta property="og:site_name" content="Earworms" />
        <meta property="og:title" content="Earworms" />
        <meta
          property="og:description"
          content="Share your favourite track of the week"
        />
        <meta name="twitter:title" content="Earworms" />
        <meta
          name="twitter:description"
          content="Share your favourite track of the week"
        />
        <meta property="og:image" content="/earworms_square.png" />
      </Helmet>

      {typeof window === "undefined" ? <WelcomeCard /> : null}

      {!user ? <GlobalFeed /> : <MyFeed />}
    </>
  )
}
