import React from "react"
import { containerStyle, titleStyle } from "./ProfileInfoBox.css"

type ProfileInfoBoxProps = {
  children: React.ReactNode
  title: string
}

export default function ProfileInfoBox(props: ProfileInfoBoxProps) {
  const { children, title } = props

  return (
    <div className={containerStyle}>
      <p className={titleStyle}>{title}</p>
      {children}
    </div>
  )
}
