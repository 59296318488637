import React, { useState } from "react"
import { Route, Switch, useParams } from "react-router-dom"
import { gql, useQuery } from "@apollo/client"
import { Helmet } from "react-helmet"

import { EARWORM_BASE } from "../../fragments"
import FeedList from "../../FeedList"
import PageNotFound from "../page-not-found"
import ProfileEarwormsList from "./ProfileEarwormsList"
import ProfileFollowersList from "./ProfileFollowersList"
import ProfileFollowingList from "./ProfileFollowingList"
import ProfileHeader from "./ProfileHeader"
import ProfileHeaderEdit from "./ProfileHeaderEdit"

// Types
import { Profile } from "../../types/profile"

const PROFILE = gql`
  ${EARWORM_BASE}

  query Profile($handle: String!) {
    profile(handle: $handle) {
      bio
      followerCount
      followingCount
      genres {
        id
        name
      }
      handle
      id
      isFollowing
      location {
        country
        id
        name
      }
      name
      profileImage {
        filename
        id
      }
      recentEarworms {
        ...EarwormBaseData
      }
      thisWeeksEarworm {
        ...EarwormBaseData
      }
      totalEarworms
    }
  }
`

interface ParamsType {
  handle: string
}

type ProfileQueryData = {
  profile: Profile
}

export default function ProfilePage() {
  const { handle: handleParam } = useParams<ParamsType>()

  const [isEditing, setIsEditing] = useState(false)

  const { data } = useQuery<ProfileQueryData>(PROFILE, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: { handle: handleParam },
  })

  if (!data) {
    return null
  }

  const profile = data?.profile

  if (!profile) {
    return <PageNotFound />
  }

  const { handle, name } = profile

  return (
    <>
      <Helmet>
        <title>{`@${handle} · ${name} · Earworms`}</title>
        <meta property="og:title" content={`@${handle} · ${name} · Earworms`} />
        <meta
          property="og:description"
          content={`@${handle} · ${name} · Earworms`}
        />
        <meta
          name="twitter:title"
          content={`@${handle} · ${name} · Earworms`}
        />
        <meta
          name="twitter:description"
          content={`@${handle} · ${name} · Earworms`}
        />
      </Helmet>

      <FeedList centre>
        {isEditing ? (
          <ProfileHeaderEdit
            onComplete={() => setIsEditing(false)}
            profile={profile}
          />
        ) : (
          <ProfileHeader
            onStartEditing={() => setIsEditing(true)}
            profile={profile}
          />
        )}
      </FeedList>

      <Switch>
        <Route exact path="/:handle">
          <ProfileEarwormsList profile={profile} />
        </Route>

        <Route exact path="/:handle/followers">
          <Helmet>
            <title>{`People following @${handle} · Earworms`}</title>
            <meta
              property="og:title"
              content={`People following @${handle} · Earworms`}
            />
            <meta
              property="og:description"
              content={`People following @${handle} · Earworms`}
            />
            <meta
              name="twitter:title"
              content={`People following @${handle} · Earworms`}
            />
            <meta
              name="twitter:description"
              content={`People following @${handle} · Earworms`}
            />
          </Helmet>
          <ProfileFollowersList />
        </Route>

        <Route exact path="/:handle/following">
          <Helmet>
            <title>{`People @${handle} follows · Earworms`}</title>
            <meta
              property="og:title"
              content={`People @${handle} follows · Earworms`}
            />
            <meta
              property="og:description"
              content={`People @${handle} follows · Earworms`}
            />
            <meta
              name="twitter:title"
              content={`People @${handle} follows · Earworms`}
            />
            <meta
              name="twitter:description"
              content={`People @${handle} follows · Earworms`}
            />
          </Helmet>
          <ProfileFollowingList />
        </Route>
      </Switch>
    </>
  )
}
