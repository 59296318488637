import React from "react"
import { Helmet } from "react-helmet"

import GlobalFeed from "./GlobalFeed"

export default function Global() {
  return (
    <>
      <Helmet>
        <title>{`Earworms`}</title>
        <meta property="og:site_name" content="Earworms" />
        <meta property="og:title" content="Earworms" />
        <meta
          property="og:description"
          content="Share your favourite track of the week"
        />
        <meta name="twitter:title" content="Earworms" />
        <meta
          name="twitter:description"
          content="Share your favourite track of the week"
        />
        <meta property="og:image" content="/earworms_square.png" />
      </Helmet>
      <GlobalFeed />
    </>
  )
}
