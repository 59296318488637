import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { gql, useLazyQuery, useMutation } from "@apollo/client"
import { Formik } from "formik"
import debounce from "lodash.debounce"
import { Helmet } from "react-helmet"
import Cookies from "js-cookie"

import { useUser } from "../contexts/user-context"
import Button from "../Button"
import FeedList from "../FeedList"
import HelpText from "../HelpText"
import Input from "../Input"
import MessageBox from "../MessageBox"

// Styles
import { fieldStyles } from "../Field.css"
import { labelStyles } from "../Label.css"
import { titleStyles } from "./join.css"

const DOES_HANDLE_EXIST = gql`
  query IsHandleAvailable($handle: String) {
    isHandleAvailable(handle: $handle) {
      handle
      isAvailable
    }
  }
`

const CREATE_USER = gql`
  mutation createUser($user: CreateUserInput!) {
    createUser(input: $user) {
      email
      profile {
        handle
        id
        isFollowing
        location {
          country
          id
          name
        }
        profileImage {
          filename
          id
        }
        name
      }
      token
    }
  }
`

export default function Join() {
  const history = useHistory()

  const { setUser } = useUser()

  const [hasError, setHasError] = useState(false)
  const [isHandleAvailable, setIsHandleAvailable] = useState<{
    handle: string
    isAvailable: boolean
  } | null>(null)

  const [doesHandleExist, { loading: loadingDoesHandleExist }] = useLazyQuery(
    DOES_HANDLE_EXIST,
    {
      onCompleted(data) {
        setIsHandleAvailable(data.isHandleAvailable)
      },
    }
  )

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onCompleted(data) {
      // Store token as cookie
      Cookies.set("token", data?.createUser?.token, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        sameSite: window.location.hostname === "localhost" ? "Lax" : "None",
        secure: window.location.protocol === "https:",
        expires: 7,
      })

      setUser(data.createUser)

      // Redirect to the welcome page
      history.push("/welcome")
    },
    onError() {
      setHasError(true)
    },
  })

  const debouncedDoesHandleExist = debounce(doesHandleExist, 500)

  const initialValues = {
    email: "",
    handle: "",
    name: "",
    password: "",
  }

  const handleSubmit = (values: any) => {
    const { email, handle, name, password } = values

    createUser({
      variables: {
        user: {
          email,
          handle,
          name,
          password,
        },
      },
    })
  }

  const validateForm = (values: any) => {
    const errors: any = {}

    if (!values.email) {
      errors.email = "Please enter your email address"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address"
    }

    if (!values.handle) {
      errors.handle = "Please choose a username"
    } else if (!/^[a-zA-Z0-9_]*$/i.test(values.handle)) {
      errors.handle =
        "Usernames can only contain letters, numbers and underscores"
    }

    if (!values.name) {
      errors.name = "Please enter your name"
    }

    if (!values.password) {
      errors.password = "Please choose a password"
    }

    return errors
  }

  const renderForm = ({
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    values,
  }: any) => {
    const handleHandleChange = (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value

      setIsHandleAvailable(null)

      if (value !== "") {
        debouncedDoesHandleExist({ variables: { handle: value } })
      }

      handleChange(e)
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className={fieldStyles}>
          <label className={labelStyles}>Username</label>
          <Input
            autoFocus
            autoComplete="nickname"
            isError={touched.handle && errors.handle}
            isLoading={loadingDoesHandleExist}
            name="handle"
            onBlur={handleBlur}
            onChange={handleHandleChange}
            placeholder="Username"
            type="text"
            value={values.handle}
          />
          {touched.handle && errors.handle ? (
            <HelpText isDanger>{errors.handle}</HelpText>
          ) : (
            <HelpText isDanger={isHandleAvailable?.isAvailable === false}>
              {isHandleAvailable?.handle === values?.handle ? (
                <>
                  {isHandleAvailable?.isAvailable === true &&
                    `@${values.handle} is available!`}
                  {isHandleAvailable?.isAvailable === false &&
                    `Sorry, @${values.handle} is already taken`}
                </>
              ) : (
                <br />
              )}
            </HelpText>
          )}
        </div>

        <div className={fieldStyles}>
          <label className={labelStyles}>Email</label>
          <Input
            autoComplete="email"
            isError={touched.email && errors.email}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Email"
            type="email"
            value={values.email}
          />
          {touched.email && errors.email ? (
            <HelpText isDanger>{errors.email}</HelpText>
          ) : null}
        </div>

        <div className={fieldStyles}>
          <label className={labelStyles}>Name</label>
          <Input
            autoComplete="name"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Your Name"
            type="text"
            value={values.name}
          />
          {touched.name && errors.name ? (
            <HelpText isDanger>{errors.name}</HelpText>
          ) : null}
        </div>

        <div className={fieldStyles}>
          <label className={labelStyles}>Password</label>
          <Input
            autoComplete="new-password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Password"
            value={values.password}
            type="password"
          />
          {touched.password && errors.password ? (
            <HelpText isDanger>{errors.password}</HelpText>
          ) : null}
        </div>

        {hasError && (
          <MessageBox
            header={
              <p>
                Something went wrong{" "}
                <span role="img" aria-label="frown emoji">
                  😦
                </span>
              </p>
            }
            isError
          >
            Something went wrong trying to create your account. Please make sure
            you haven't already registered with this email address.
          </MessageBox>
        )}

        <div className={fieldStyles}>
          <Button
            buttonStyle="link"
            disabled={
              loading ||
              Object.keys(errors).length > 0 ||
              !isHandleAvailable?.isAvailable
            }
            type="submit"
          >
            Join Earworms
          </Button>
        </div>
      </form>
    )
  }

  return (
    <>
      <Helmet>
        <title>{`Join · Earworms`}</title>
      </Helmet>

      <FeedList>
        <h3 className={titleStyles}>Join Earworms</h3>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validateForm}
          validateOnMount={true}
        >
          {renderForm}
        </Formik>
      </FeedList>
    </>
  )
}
