import React, { useState } from "react"
import { gql, useQuery } from "@apollo/client"

import { EARWORM_BASE } from "../../fragments"
import { useUser } from "../../contexts/user-context"
import Button from "../../Button"
import FeedList from "../../FeedList"
import LargeEarwormFeedItem from "../../LargeEarwormFeedItem"
import PlayMyFeed from "./PlayMyFeed"

// Types
import { EarwormType } from "../../types/earworm"

const MY_FEED = gql`
  ${EARWORM_BASE}

  query MyFeed($offset: Int, $limit: Int) {
    myFeed(offset: $offset, limit: $limit) {
      ...EarwormBaseData
    }
  }
`

type MyFeedData = {
  myFeed: Array<EarwormType>
}

const NUMBER_OF_EARWORMS_PER_PAGE = 10

export default function MyFeed() {
  const { isAuthenticatingUser, user } = useUser()

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(true)

  const { data, fetchMore, loading } = useQuery<MyFeedData>(MY_FEED, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    variables: {
      limit: NUMBER_OF_EARWORMS_PER_PAGE,
      offset: 0,
    },
    onCompleted(data) {
      // Called when the query completes (first time, not after fetchMore)
      if (data?.myFeed?.length === NUMBER_OF_EARWORMS_PER_PAGE) {
        setShowLoadMoreButton(true)
      }
    },
  })

  const fetchMoreFeedEarworms = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // Remove the focus from the load more button to prevent scroll jumping
    event.currentTarget.blur()

    fetchMore({
      variables: {
        offset: data?.myFeed?.length || 0,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          setShowLoadMoreButton(false)
          return prev
        }

        if (fetchMoreResult.myFeed.length < NUMBER_OF_EARWORMS_PER_PAGE) {
          setShowLoadMoreButton(false)
        } else {
          setShowLoadMoreButton(true)
        }

        return Object.assign({}, prev, {
          myFeed: [...prev.myFeed, ...fetchMoreResult.myFeed],
        })
      },
    })
  }

  if (!user || isAuthenticatingUser) {
    return null
  }

  if (!data?.myFeed) {
    return null
  }

  return (
    <FeedList>
      <PlayMyFeed earworms={data?.myFeed} />

      {data?.myFeed?.map((earworm: EarwormType) => (
        <LargeEarwormFeedItem key={earworm.id} earworm={earworm} />
      ))}

      {showLoadMoreButton && (
        <Button
          buttonStyle="link"
          isLoading={loading}
          onClick={fetchMoreFeedEarworms}
        >
          Load More Earworms
        </Button>
      )}
    </FeedList>
  )
}
