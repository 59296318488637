import React from "react"

import MainNavLinks from "./MainNavLinks"
import { mobileNavContainer } from "./MobileNav.css"

export default function MobileNav() {
  return (
    <nav className={mobileNavContainer}>
      <MainNavLinks />
    </nav>
  )
}
