import { detect } from "detect-browser"

const SUPPORTED_BROWSERS = [
  "Chrome",
  "Firefox",
  "Edge",
  "Internet Explorer",
  "IE",
]
const SUPPORTED_OPERATING_SYSTEMS = ["Windows", "Mac", "Linux"]

/**
 * Returns true if the current browser can stream Spotify tracks
 *
 * @returns true if the current browser can stream Spotify tracks
 */
export default function supportsSpotifyPlayback() {
  const browser = detect()

  // If we can't detect info about this browser then return false
  if (!browser) {
    return false
  }

  // Detect if this a supported operating system from the list above
  const isOperatingSystemSupported = SUPPORTED_OPERATING_SYSTEMS.findIndex(
    (os) => {
      if (!browser || !browser.os) {
        return false
      }
      return browser.os.indexOf(os) > -1
    }
  )

  // If this OS isn't supported then return false
  if (isOperatingSystemSupported === -1) {
    return false
  }

  // Detect if this browser is supported from the list above
  const isBrowserSupported = SUPPORTED_BROWSERS.findIndex((browserName) => {
    if (!browser || !browser.name) {
      return false
    }
    return browser.name.toLowerCase().indexOf(browserName.toLowerCase()) > -1
  })

  // If this browser is supported then return true, otherwise false
  return isBrowserSupported > -1
}
