import "src/pages/share/index.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/pages/share/index.css.ts.vanilla.css\",\"source\":\"Ll8xdTdqZWxlMCB7CiAgbWFyZ2luLWJvdHRvbTogMS41cmVtOwogIGZvbnQtc2l6ZTogMS41cmVtOwogIGxpbmUtaGVpZ2h0OiAxLjEyNTsKICBmb250LXdlaWdodDogNjAwOwogIGNvbG9yOiAjMzYzNjM2Owp9Ci5fMXU3amVsZTEgewogIG1hcmdpbi10b3A6IDVyZW07CiAgYmFja2dyb3VuZC1jb2xvcjogI2Y1ZjVmNTsKICBib3JkZXItcmFkaXVzOiAwLjM3NXJlbTsKICBmb250LXNpemU6IDAuOXJlbTsKfQouXzF1N2plbGUyIHsKICBwYWRkaW5nOiAxLjI1cmVtIDEuNXJlbTsKICBib3JkZXItY29sb3I6ICNkYmRiZGI7CiAgYm9yZGVyLXJhZGl1czogMC4zNzVyZW07CiAgYm9yZGVyLXN0eWxlOiBzb2xpZDsKICBib3JkZXItd2lkdGg6IDAgMCAwIDRweDsKICBjb2xvcjogIzRhNGE0YTsKfQouXzF1N2plbGUzIHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiByb3c7Cn0KLl8xdTdqZWxlNCB7CiAgbWFyZ2luLXJpZ2h0OiAwLjVyZW07CiAgbWFyZ2luLWJvdHRvbTogMC41cmVtOwogIHdpZHRoOiA4cmVtOwogIGhlaWdodDogOHJlbTsKICBtYXgtd2lkdGg6IDhyZW07CiAgbWF4LWhlaWdodDogOHJlbTsKfQouXzF1N2plbGU1IHsKICBkaXNwbGF5OiBibG9jazsKfQouXzF1N2plbGU2IHsKICBkaXNwbGF5OiBmbGV4OwogIG1hcmdpbjogMC41cmVtIDAgMS41cmVtIDA7CiAgZm9udC1zaXplOiAxcmVtOwp9Ci5fMXU3amVsZTcgewogIGZsZXg6IDEgMCBhdXRvOwogIG1hcmdpbi1yaWdodDogMC41cmVtOwogIHdpZHRoOiAxOHB4OwogIGhlaWdodDogMThweDsKfQouXzF1N2plbGU4IHsKICBjdXJzb3I6IHBvaW50ZXI7Cn0KLl8xdTdqZWxlOSB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogcm93OwogIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjsKfQouXzF1N2plbGUwIC50d2Vtb2ppIHsKICB3aWR0aDogMS41cmVtOwogIGhlaWdodDogMS41cmVtOwp9\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var buttonsStyle = '_1u7jele9';
export var checkboxContainerStyle = '_1u7jele6';
export var checkboxLabelStyle = '_1u7jele8';
export var checkboxStyle = '_1u7jele7';
export var coverArtStyle = '_1u7jele4';
export var messageBodyStyle = '_1u7jele2';
export var messageStyle = '_1u7jele1';
export var titleStyle = '_1u7jele0';
export var trackDetailsStyle = '_1u7jele3';
export var trackNameStyle = '_1u7jele5';