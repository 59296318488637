import React from "react"
import {
  inputContainerIsLoadingStyle,
  inputContainerStyle,
  inputErrorStyle,
  inputStyle,
} from "./Input.css"

type InputProps = {
  ["aria-activedescendant"]?: string
  ["aria-autocomplete"]?: "none" | "list" | "inline" | "both"
  ["aria-controls"]?: string
  autoComplete?:
    | "current-password"
    | "email"
    | "name"
    | "new-password"
    | "nickname"
    | "off"
    | "username"
  autoFocus?: boolean
  className?: string
  disabled?: boolean
  isError?: boolean
  isLoading?: boolean
  key?: string
  name?: string
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
  type?: "email" | "password" | "text"
  value?: string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    "aria-activedescendant": ariaactivedescendant,
    "aria-autocomplete": ariaautocomplete,
    "aria-controls": ariacontrols,
    autoComplete,
    autoFocus = false,
    className,
    disabled = false,
    isError = false,
    isLoading = false,
    key,
    name,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    placeholder,
    type,
    value,
  } = props

  return (
    <div
      className={
        isLoading
          ? `${inputContainerStyle} ${inputContainerIsLoadingStyle}`
          : `${inputContainerStyle}`
      }
    >
      <input
        aria-activedescendant={ariaactivedescendant}
        aria-autocomplete={ariaautocomplete}
        aria-controls={ariacontrols}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={
          isError
            ? `${inputStyle} ${inputErrorStyle} ${className}`
            : `${inputStyle} ${className}`
        }
        disabled={disabled}
        key={key}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={ref}
        type={type}
        value={value}
      />
    </div>
  )
})

export default Input
