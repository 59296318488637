import React from "react"

import FeedList from "../FeedList"
import LinkButton from "../LinkButton"
import { heroStyle, titleStyle } from "./page-not-found.css"

export default function PageNotFound() {
  return (
    <section className={heroStyle}>
      <FeedList>
        <h1 className={titleStyle}>
          Sorry, we couldn't find that page{" "}
          <span role="img" aria-label="Shrugging person emoji">
            🤷‍♀️
          </span>
        </h1>

        <LinkButton buttonStyle="link" to="/">
          Go to Earworms home
        </LinkButton>
      </FeedList>
    </section>
  )
}
