import React from "react"
import { Link } from "react-router-dom"

import ProfileImage from "../ProfileImage"

// Types
import { Profile } from "../../../types/profile"
import { linkStyle } from "./MoreEarwormsByProfile.css"

type MoreEarwormsByProfileProps = {
  profile: Profile
}

export default function MoreEarwormsByProfile(
  props: MoreEarwormsByProfileProps
) {
  const { profile } = props

  if (!profile) {
    return null
  }

  const { handle, profileImage } = profile

  return (
    <Link className={linkStyle} to={`/${handle}`}>
      <ProfileImage
        alt={`Profile image of @{handle}`}
        src={`/img256/${profileImage.filename}`}
      />
      See more Earworms shared by @{handle}
    </Link>
  )
}
