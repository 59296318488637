import React, { useState } from "react"
import { Link } from "react-router-dom"
import { gql, useApolloClient, useMutation } from "@apollo/client"
import emojiData from "@emoji-mart/data"
import Picker from "@emoji-mart/react"
import Twemoji from "react-twemoji"
import Plausible from "plausible-tracker"

import { useSpotifyPlayer } from "./contexts/spotify-player-context"
import { useUser } from "./contexts/user-context"
import AddComment from "./AddComment"
import Button from "./Button"
import ExternalLinkButton from "./ExternalLinkButton"
import LargeEarwormFeedItemFooterComments from "./LargeEarwormFeedItemComments"
import LargeEarwormFeedItemGenres from "./LargeEarwormFeedItemGenres"
import LazyTippy from "./LazyTippy"

// Icons
import { ReactComponent as Play } from "./icons/play.svg"
import { ReactComponent as Smile } from "./icons/smile.svg"

// Types
import { EarwormType, EmojiReaction } from "./types/earworm"
import { User } from "./types/user"

// Styles
import {
  largeEarwormFeedItemFooterButtonIsCurrentUserStyles,
  largeEarwormFeedItemFooterButtonStyles,
  largeEarwormFeedItemFooterContainerStyles,
  largeEarwormFeedItemFooterEmojiButtonContainerStyles,
  largeEarwormFeedItemFooterEmojiButtonSectionStyles,
  largeEarwormFeedItemFooterEmojiReactionTwemojiStyles,
  largeEarwormFeedItemFooterEmojisStyles,
  largeEarwormFeedItemFooterOpenTrackButtonStyles,
  largeEarwormFeedItemFooterPlayIconStyles,
  largeEarwormFeedItemFooterPlayTrackButtonStyles,
  largeEarwormFeedItemFooterReactionProfileLinksStyles,
  largeEarwormFeedItemFooterReactionProfileLinkStyles,
  largeEarwormFeedItemFooterSmileIconStyles,
} from "./LargeEarwormFeedItemFooter.css"

const { trackEvent } = Plausible()

const TOGGLE_EMOJI_REACTION = gql`
  mutation toggleEmojiReaction($earwormId: String!, $emoji: String!) {
    toggleEmojiReaction(earwormId: $earwormId, emoji: $emoji) {
      earworm {
        emojiReactions {
          count
          earwormId
          emoji
          profiles {
            handle
            id
            name
          }
        }
        id
      }
    }
  }
`

const isAppliedByUser = (user: User | null, emojiReaction: EmojiReaction) => {
  if (!user) {
    return false
  }

  return !!emojiReaction?.profiles?.find((profile) => {
    if (!profile) {
      return null
    }

    return profile.id === user?.profile?.id
  })
}

type LargeEarwormFeedItemFooterProps = {
  earworm: EarwormType
}

export default function LargeEarwormFeedItemFooter(
  props: LargeEarwormFeedItemFooterProps
) {
  const { earworm } = props
  const { emojiReactions, spotifyId } = earworm

  const client = useApolloClient()

  const { user } = useUser()
  const { setEarworms, spotifyIsSupported } = useSpotifyPlayer()

  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const [toggleEmojiReaction] = useMutation(TOGGLE_EMOJI_REACTION, {
    onCompleted(data) {
      const earworm = data?.toggleEmojiReaction?.earworm

      if (!earworm) {
        return
      }

      client.writeFragment({
        id: earworm.id,
        fragment: gql`
          fragment MyEarworm on Earworm {
            emojiReactions {
              count
              earwormId
              emoji
              profiles {
                handle
                id
                name
              }
            }
            id
          }
        `,
        data: earworm,
      })
    },
  })

  if (!spotifyId) {
    return null
  }

  const handleEmojiButtonClick = (emoji: string) => {
    toggleEmojiReaction({
      variables: { earwormId: earworm.id, emoji },
    })
  }

  const handleEmojiSelect = (emoji: { native: string }) => {
    setShowEmojiPicker(false)
    toggleEmojiReaction({
      variables: { earwormId: earworm.id, emoji: emoji.native },
    })
  }

  const handleOpenTrackClick = () => {
    // Track open in Spotify event
    trackEvent("Played an Earworm", { props: { label: "On Spotify" } })
  }

  const handlePlayTrackClick = () => {
    // Track play event
    trackEvent("Played Track inline", { props: { label: "On Spotify" } })

    setEarworms([earworm])
  }

  const renderPlayButton = () => {
    // If Spotify streaming isn't supported on this device,
    // then render an "Open in Spotify" link
    if (!spotifyIsSupported) {
      return (
        <ExternalLinkButton
          buttonStyle="link"
          className={largeEarwormFeedItemFooterOpenTrackButtonStyles}
          href={`https://open.spotify.com/track/${spotifyId}`}
          onClick={handleOpenTrackClick}
        >
          <Play className={largeEarwormFeedItemFooterPlayIconStyles} />
          <span>Open in Spotify</span>
        </ExternalLinkButton>
      )
    }

    // Render the stream track button
    return (
      <Button
        buttonStyle="link"
        className={largeEarwormFeedItemFooterPlayTrackButtonStyles}
        onClick={handlePlayTrackClick}
        type="button"
      >
        <Play className={largeEarwormFeedItemFooterPlayIconStyles} />
        <span>Play track</span>
      </Button>
    )
  }

  return (
    <div className={largeEarwormFeedItemFooterContainerStyles}>
      {renderPlayButton()}

      {earworm?.genres ? (
        <LargeEarwormFeedItemGenres genres={earworm.genres} />
      ) : null}

      <div className={largeEarwormFeedItemFooterEmojisStyles}>
        {emojiReactions.map((emojiReaction) => (
          <div
            className={largeEarwormFeedItemFooterEmojiButtonContainerStyles}
            key={emojiReaction.emoji}
          >
            <LazyTippy
              animation={false}
              arrow={true}
              content={
                <div
                  className={
                    largeEarwormFeedItemFooterReactionProfileLinksStyles
                  }
                >
                  {emojiReaction.profiles.map((profile) => (
                    <Link
                      key={profile.id}
                      className={
                        largeEarwormFeedItemFooterReactionProfileLinkStyles
                      }
                      to={`/${profile.handle}`}
                    >
                      {`@${profile.handle}`}
                    </Link>
                  ))}
                </div>
              }
              interactive={true}
              touch="hold"
            >
              <button
                className={
                  isAppliedByUser(user, emojiReaction)
                    ? `${largeEarwormFeedItemFooterButtonStyles} ${largeEarwormFeedItemFooterButtonIsCurrentUserStyles}`
                    : `${largeEarwormFeedItemFooterButtonStyles}`
                }
                disabled={!user}
                onClick={() => handleEmojiButtonClick(emojiReaction.emoji)}
              >
                <Twemoji
                  className={
                    largeEarwormFeedItemFooterEmojiReactionTwemojiStyles
                  }
                  options={{ className: "twemoji", noWrapper: true }}
                >
                  {emojiReaction.emoji}
                </Twemoji>
                <span>{emojiReaction.count}</span>
              </button>
            </LazyTippy>
          </div>
        ))}

        {!!user ? (
          <LazyTippy
            animation={false}
            content={
              <Picker data={emojiData} onEmojiSelect={handleEmojiSelect} />
            }
            interactive={true}
            onClickOutside={() => setShowEmojiPicker(false)}
            placement="top"
            touch="hold"
            visible={showEmojiPicker}
          >
            <div className={largeEarwormFeedItemFooterEmojiButtonSectionStyles}>
              <button
                className={largeEarwormFeedItemFooterButtonStyles}
                onClick={() => setShowEmojiPicker((v) => !v)}
              >
                React{" "}
                <Smile className={largeEarwormFeedItemFooterSmileIconStyles} />
              </button>
            </div>
          </LazyTippy>
        ) : null}
      </div>

      {earworm?.comments?.length > 0 ? (
        <LargeEarwormFeedItemFooterComments comments={earworm.comments} />
      ) : null}
      {!!user ? <AddComment earwormId={earworm.id} /> : null}
    </div>
  )
}
