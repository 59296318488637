import React, { useState } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { gql, useLazyQuery } from "@apollo/client"
import { Formik } from "formik"
import { Helmet } from "react-helmet"
import Cookies from "js-cookie"
import * as Sentry from "@sentry/react"

import { EARWORM_BASE } from "../fragments"
import { useUser } from "../contexts/user-context"
import Button from "../Button"
import FeedList from "../FeedList"
import Input from "../Input"
import { feedTitleStyle } from "../FeedTitle.css"
import { joinLinkStyle, signInTitleStyle } from "./signin.css"
import { fieldStyles } from "../Field.css"
import { labelStyles } from "../Label.css"
import { formErrorTextStyle } from "../FormErrorText.css"

const SIGN_IN = gql`
  ${EARWORM_BASE}

  query SignIn($email: String, $password: String) {
    signIn(email: $email, password: $password) {
      email
      profile {
        handle
        id
        isConnectedToSpotify
        name
        thisWeeksEarworm {
          ...EarwormBaseData
        }
      }
      token
    }
  }
`

export default function Signin() {
  const history = useHistory()
  const location = useLocation<any>()
  const redirectTo = location?.state?.redirectTo || null

  const { setUser } = useUser()

  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
  })
  const [signInError, setSignInError] = useState(false)

  const [signIn] = useLazyQuery(SIGN_IN, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (!!data?.signIn?.token) {
        // Store token as cookie
        Cookies.set("token", data?.signIn?.token, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          sameSite: window.location.hostname === "localhost" ? "Lax" : "None",
          secure: window.location.protocol === "https:",
          expires: 7,
        })

        setUser(data?.signIn)

        Sentry.setUser({ username: data.signIn.profile.handle })

        if (redirectTo) {
          history.push(redirectTo)
        } else {
          history.push("/")
        }
      } else {
        setSignInError(true)
        setInitialValues({ email: "", password: "" })
        Sentry.setUser(null)
      }
    },
  })

  const handleSubmit = async (values: any) => {
    const { email, password } = values

    signIn({
      variables: {
        email,
        password,
      },
    })
  }

  const validateForm = (values: any) => {
    const errors: any = {}

    if (!values.email) {
      errors.email = "Required"
    }

    if (!values.password) {
      errors.name = "Required"
    }

    return errors
  }

  const renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  }: any) => {
    return (
      <form onSubmit={handleSubmit}>
        <div className={fieldStyles}>
          <label className={labelStyles}>Email</label>
          <Input
            autoComplete="username"
            autoFocus
            isError={touched.email && errors.email}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Email"
            type="email"
            value={values.email}
          />
          {touched.email && errors.email ? (
            <p className={formErrorTextStyle}>{errors.email}</p>
          ) : null}
        </div>

        <div className={fieldStyles}>
          <label className={labelStyles} htmlFor="password">
            Password
          </label>
          <Input
            autoComplete="current-password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Password"
            type="password"
            value={values.password}
          />
          {touched.password && errors.password ? (
            <p className={formErrorTextStyle}>{errors.password}</p>
          ) : null}
        </div>

        {signInError && (
          <p className={formErrorTextStyle}>
            Sorry, your email or password were not recognised
          </p>
        )}

        <Button
          buttonStyle="link"
          disabled={isSubmitting}
          isLoading={isSubmitting}
          type="submit"
        >
          Sign in to Earworms
        </Button>
      </form>
    )
  }

  return (
    <>
      <Helmet>
        <title>{`Sign in · Earworms`}</title>
      </Helmet>

      <FeedList>
        <h4 className={feedTitleStyle}>Sign in to Earworms</h4>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validateForm}
        >
          {renderForm}
        </Formik>
      </FeedList>

      <FeedList>
        <h4 className={`${feedTitleStyle} ${signInTitleStyle}`}>
          Don't have an account?
        </h4>
        <Link
          className={joinLinkStyle}
          to={{ pathname: "/join", state: { redirectTo } }}
        >
          Join Earworms
        </Link>
      </FeedList>
    </>
  )
}
