import { useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { gql, useMutation } from "@apollo/client"

import { useUser } from "../../contexts/user-context"

const STORE_CONNECTED_USER = gql`
  mutation storeConnectedUser($code: String!) {
    storeConnectedUser(code: $code) {
      profile {
        id
        isConnectedToSpotify
      }
      success
    }
  }
`

export default function SpotifyCallback() {
  const history = useHistory()
  const currentLocation = useLocation()

  const { refetchUser, user } = useUser()

  const [storeConnectedUser] = useMutation(STORE_CONNECTED_USER, {
    fetchPolicy: "no-cache",
    onCompleted(data) {
      if (refetchUser) {
        refetchUser()
      }

      // TODO: In the future this should go back to where the user came from
      // Go back to home page
      history.replace("/")
    },
  })

  useEffect(() => {
    if (!user) {
      return
    }

    const queryParams = new URLSearchParams(currentLocation.search)

    const code = queryParams.get("code")

    // TODO: redirect somewhere with error
    if (!code) {
      return
    }

    // Send this code to the server, which will request a refresh_token
    // and store it in the DB, so we can do things on behalf of this user
    // in the future
    storeConnectedUser({
      variables: { code },
    })
  }, [currentLocation, storeConnectedUser, user])

  return null
}
