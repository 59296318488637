import React, { TextareaHTMLAttributes } from "react"
import { textareaStyle } from "./pages/profile/Textarea.css"

type TextareaProps = {
  children?: React.ReactNode
}

const Textarea = ({
  children,
  ...rest
}: TextareaHTMLAttributes<HTMLTextAreaElement> & TextareaProps) => {
  return (
    <textarea {...rest} className={`${textareaStyle} ${rest.className}`}>
      {children}
    </textarea>
  )
}

export default Textarea
