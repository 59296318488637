import React, { useEffect } from "react"
import { Route, Switch, useHistory } from "react-router-dom"

import { useUser } from "../../contexts/user-context"
import PlaylistsPage from "./PlaylistsPage"

export default function Playlists() {
  const history = useHistory()
  const { isAuthenticatingUser, user } = useUser()

  // If the user is not signed in, then redirect to signin page
  useEffect(() => {
    if (!user && !isAuthenticatingUser) {
      history.push("/signin", { redirectTo: "/playlists" })
    }
  }, [history, isAuthenticatingUser, user])

  if (!user) {
    return null
  }

  return (
    <Switch>
      <Route exact path="/:handle">
        <PlaylistsPage />
      </Route>
    </Switch>
  )
}
